import React from "react";
import {Card} from "../../component/card/card";
import { useSelector } from "react-redux";
import { addProtection, deleteProtection } from "../../store/storeData";
import { DynamicArrayProtection } from "./dynamicArrayProtection";

export function Protection() {
    
    const fields = useSelector(state => state.inputProtection.protection);

    return (
        <Card>
            <h3>
                Produit de Protection
            </h3>
            <DynamicArrayProtection fields={fields} create={"Ajouter un produit de protection"}
                                      delete={"Effacer un produit de protection"}
                                      addValueReducer={addProtection} removeValueReducer={deleteProtection}>
                
            </DynamicArrayProtection>
        </Card>
    );

}