import React from "react";
import { Card } from "../card/card";
import "./members.scss";
import "./marie.scss";
import { MyInput } from "../input/myInput";
import Input from "react-phone-number-input/input";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDateConjoint,
  setEmailConjoint,
  setFirstNameConjoint,
  setGenderConjoint,
  setLastNameConjoint,
  setPhoneUser,
  setPhoneUserConjoint,
  setProfessionConjoint,
  setSocieteConjoint,
  setStatusSalarialConjoint,
  setRegimeMatrimonialConjoint,
} from "../../store/storeData";
import { putSpaces } from "../../utils/functions";

export function Marie(props) {
  const [phone] = useState();
  const dispatch = useDispatch();

  return (
    <div className={"Marie"}>
      <Card>
        <div>
          <div className={"line"} style={{paddingTop: "30px"}}>
            <MyInput
                obligatoire={true}
                placeholder={"Nom Conjoint "}
              value={useSelector((state) => state.inputConjoint.lastName)}
              onChange={(event) => {
                dispatch(setLastNameConjoint(event.target.value));
              }}
            />
            <MyInput
                obligatoire={true}
                placeholder={"Prénom Conjoint"}
              value={useSelector((state) => state.inputConjoint.firstName)}
              onChange={(event) => {
                dispatch(setFirstNameConjoint(event.target.value));
              }}
            />
          </div>
          <div className={"line"}>
            <MyInput
                obligatoire={true}
                placeholder="dd-mm-yyyy"
              className={"date"}
              type={"date"}
              value={useSelector((state) => state.inputConjoint.date)}
              onChange={(event) => {
                dispatch(setDateConjoint(event.target.value));
              }}
            />
            <div className={"Dropdown"}>
              <div className={"Dropdown-select"}>
                <form>
                  <select
                    className={"select"}
                    name="Statut Familial"
                    onChange={(e) =>
                      dispatch(setRegimeMatrimonialConjoint(e.target.value))
                    }
                    id="lang"
                  >
                    <option value="">
                      Régime mariage
                    </option>
                    <option value="Séparation de biens">
                      Séparation de biens
                    </option>
                    <option value="Communauté_legale">Communauté légale</option>
                    <option value="Communauté universelle">
                      Communauté universelle
                    </option>
                    <option value="Communauté de meubles et acquêts (avant février 1966)">
                      Communauté de meubles et acquêts (avant février 1966)
                    </option>
                    <option value="Participation aux acquêts">
                      Participation aux acquêts
                    </option>
                  </select>
                </form>
              </div>
            </div>
          </div>
          <div className={"line"}>
            <MyInput

              placeholder={"Email"}
              value={useSelector((state) => state.inputConjoint.email)}
              onChange={(event) => {
                dispatch(setEmailConjoint(event.target.value));
              }}
            />
            <MyInput
                obligatoire={true}
                placeholder={"Numéro de Téléphone"}
              value={useSelector((state) => state.inputConjoint.phone)}
              onChange={(event) => {
                dispatch(
                  setPhoneUserConjoint(putSpaces(event.target.value, 2))
                );
              }}
            />
          </div>
          <div className={"Profession"}>
            <div className={"line"}>
              <MyInput
                className={"Prénom"}
                placeholder={"Profession Conjoint"}
                value={useSelector((state) => state.inputConjoint.profession)}
                onChange={(event) => {
                  dispatch(setProfessionConjoint(event.target.value));
                }}
              />
              <div className={"Dropdown"}>
                <div className={"Dropdown-select"}>
                  <form>
                    <select
                      className={"select"}
                      value={useSelector(
                        (state) => state.inputConjoint.statusSalarial
                      )}
                      onChange={(event) => {
                        dispatch(setStatusSalarialConjoint(event.target.value));
                      }}
                    >
                      <option value="">Statut professionnel</option>
                      <option value="TNS (SSI, ex RSI)">TNS (SSI, ex RSI)</option>
                      <option value="Salarié(e)">Salarié(e)</option>
                      <option value="Autre">Autre</option>
                      <option value="Etudiant(e) / Apprenti(e)">Etudiant(e) / Apprenti(e)</option>
                      <option value="Demandeur d'emploi">Demandeur d'emploi</option>
                      <option value="Sans Emploi">Sans Emploi</option>
                    </select>
                  </form>
                </div>
              </div>
            </div>
            <div className={"line"}>
              <MyInput
                className={"Prénom"}
                placeholder={"Socété Conjoint"}
                value={useSelector((state) => state.inputConjoint.societe)}
                onChange={(event) => {
                  dispatch(setSocieteConjoint(event.target.value));
                }}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
