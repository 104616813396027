import React from 'react'
import "./Loader.scss";

export default function Loader(props) {
  return (
    <div className={'Loader' + (props.isActive ? " enabled" : " disabled")}>
        <h1>Communication avec SalesForce en cours, veuillez patienter ...</h1>
    </div>
  )
}
