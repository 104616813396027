import "./dynamicArrayEpargne.scss";
import { Button } from "../../component/button/button";
import { useDispatch } from "react-redux";
import { DropDown } from "../../component/dropDown/dropDown";
import { MyInput } from "../../component/input/myInput";
import {
  deleteAllProtection,
  deleteProtection,
  setAssureurProtection,
  setMensualiteProtection,
  setProduitProtection,
} from "../../store/storeData";
import { putSpaces } from "../../utils/functions";

export function DynamicArrayProtection(props) {
  const dispatch = useDispatch();

  const addCard = () => {
    dispatch(props.addValueReducer());
  };

  // Construction du tableau
  let table = [];
  for (let i = 0; i < props.fields.length; i++) {
    table.push(
      <div className={"Actif"} key={i}>
        <DropDown>
          <select
            name="Produit Protection"
            id="lang"
            className="DropDownProtection"
            value={props.fields[i].typeProtection}
            onChange={(e) => {
              dispatch(
                setProduitProtection({
                  value: e.target.value,
                  index: i,
                })
              );
            }}
          >
            <option value="Produit de Protection">Produit de Protection</option>
            <option value="PIJ">Prévoyance IJ</option>
            <option value="MS">Mutuelle santé</option>
            <option value="HC">Homme Clé</option>
            <option value="C_C">Chien/Chat</option>
          </select>
        </DropDown>
        <div className="DropDownProtection">
          <MyInput
            placeholder="Mensualité"
            id={"MyInput"}
            value={props.fields[i].mensualite}
            onChange={(e) => {
              dispatch(
                setMensualiteProtection({
                  value: putSpaces(e.target.value, 3),
                  index: i,
                })
              );
            }}
          />
          <DropDown>
            <select
              name="Assureur"
              id="lang"
              value={props.fields[i].assureur}
              onChange={(e) => {
                dispatch(
                  setAssureurProtection({
                    value: e.target.value,
                    index: i,
                  })
                );
              }}
            >
                <option value="">Assureur / Banque</option>
                <option value="AFI-ESCA">AFI-ESCA</option>
                <option value="AG2R (La Mondiale)">AG2R (La Mondiale)e</option>
                <option value="AGEAS">AGEAS</option>
                <option value="AGIPI">AGIPI</option>
                <option value="Allianz">Allianz</option>
                <option value="Alptis">Alptis</option>
                <option value="APICIL">APICIL</option>
                <option value="Aviva">Aviva</option>
                <option value="Axa">AXA</option>
                <option value="Banque Postale">Banque Postale</option>
                <option value="BNP">BNP</option>
                <option value="Caisse d'Epargne">Caisse Epargne</option>
                <option value="Cardif">Cardif</option>
                <option value="CNP">CNP</option>
                <option value="Covéa">Covéa</option>
                <option value="Crédit Agricole">Crédit Agricole</option>
                <option value="Crédit Mutuel">Crédit Mutuel</option>
                <option value="FWU">FWU</option>
                <option value="Gan">GAN</option>
                <option value="Generali">Générali</option>
                <option value="La Médicale">La Médicale</option>
                <option value="Groupama">Groupama</option>
                <option value="LCL">LCL</option>
                <option value="MACIF">MACIF</option>
                <option value="MACSF">MACSF</option>
                <option value="MATMUT">MATMUT</option>
                <option value="Médicis">Médicis</option>
                <option value="MMA">MAA</option>
                <option value="Néant">Néant</option>
                <option value="Suravenir">Suravenir</option>
                <option value="SwissLife">SwissLife</option>
                <option value="Société Générale">Société Générale</option>
                <option value="Autres">Autres</option>
            </select>
          </DropDown>
        </div>
        <div className={"Delete"}>
          <div
            className={"DeleteIcon"}
            onClick={() => {
              dispatch(deleteProtection(i));
            }}
          >
            🗑
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={"Data"}></div>
      <form onSubmit={(e) => {}}>{table}</form>
      <div className={"Manage"}>
        <div className={"AddingButton"}>
          <Button
            onClick={(e) => {
              addCard();
            }}
            className={"MyButton"}
          >
            {props.create}
          </Button>
        </div>
        <div className="DeleteAllProtection">
          <Button
            onClick={() => {
              dispatch(deleteAllProtection());
            }}
            className={"MyButton"}
          >
            {props.delete}
          </Button>
        </div>
      </div>
    </div>
  );
}
