/* eslint-disable no-empty-pattern */
import { configureStore, createSlice } from "@reduxjs/toolkit";
let today = new Date();

export const StoreData = createSlice({
  name: "storeData",
  initialState: {
    firstName: "",
    lastName: "",
    date: "",
    phone: "",
    nbrChildren: "",
    statusMarital: "",
    profession: "",
    adress: "",
    postalCode: "",
    ville: "",
    statutPro: "TNS (SSI, ex RSI)",
    societe: "",
    gender: "",
    email: "",
  },
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setPhoneUser: (state, action) => {
      state.phone = action.payload;
    },
    setNbrChildren: (state, action) => {
      state.nbrChildren = action.payload;
    },
    setStatusMarital: (state, action) => {
      state.statusMarital = action.payload;
    },
    setProfession: (state, action) => {
      state.profession = action.payload;
    },
    setAdress: (state, action) => {
      state.adress = action.payload;
    },
    setSociete: (state, action) => {
      state.societe = action.payload;
    },
    setStatutProfessionnel: (state, action) => {
      state.statutPro = action.payload;
    },
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setPostalCode: (state, action) => {
      state.postalCode = action.payload;
    },
    setTown: (state, action) => {
      state.ville = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});

export const StoreDataConjoint = createSlice({
  name: "StoreDataConjoint",
  initialState: {
    conjoint: false,
    firstName: "",
    lastName: "",
    date: "",
    phone: "",
    email: "",
    regimeMatrimonial: "",
    profession: "",
    adress: "",
    societe: "",
    statusSalarial: "TNS (SSI, ex RSI)",
    gender: "",
  },
  reducers: {
    setConjoint: (state, action) => {
      state.conjoint = action.payload;
    },
    setFirstNameConjoint: (state, action) => {
      state.firstName = action.payload;
    },
    setLastNameConjoint: (state, action) => {
      state.lastName = action.payload;
    },
    setDateConjoint: (state, action) => {
      state.date = action.payload;
    },
    setPhoneUserConjoint: (state, action) => {
      state.phone = action.payload;
    },
    setEmailConjoint: (state, action) => {
      state.email = action.payload;
    },
    setRegimeMatrimonialConjoint: (state, action) => {
      state.regimeMatrimonial = action.payload;
    },
    setProfessionConjoint: (state, action) => {
      state.profession = action.payload;
    },
    setAdressConjoint: (state, action) => {
      state.adress = action.payload;
    },
    setSocieteConjoint: (state, action) => {
      state.societe = action.payload;
    },
    setStatusSalarialConjoint: (state, action) => {
      state.statusSalarial = action.payload;
    },
    setGenderConjoint: (state, action) => {
      state.gender = action.payload;
    },
  },
});
export const StoreDataHouse = createSlice({
  name: "StoreDataHouse",
  initialState: {
    house: [
      {
        typeHouse: "Résidence Principale",
        valueAcquisition: "",
        capital: "",
        mensualite: "",
        duration: "",
        loyerPercu: "",
      },
    ],
  },
  reducers: {
    addHouse: (state, action) => {
      state.house.push({
        typeHouse: "Résidence Secondaire",
        valueAcquisition: "",
        capital: "",
        mensualite: "",
        duration: "",
        loyerPercu: "",
      });

      return state;
    },
    setTypeHouse: (state, action) => {
      const index = action.payload.index;

      state.house[index].typeHouse = action.payload.value;

      return state;
    },
    setAcquisitionHouse: (state, action) => {
      const index = action.payload.index;

      state.house[index].valueAcquisition = action.payload.value;

      return state;
    },
    setCapitalHouse: (state, action) => {
      const index = action.payload.index;

      state.house[index].capital = action.payload.value;

      return state;
    },
    setMensualiteHouse: (state, action) => {
      const index = action.payload.index;

      state.house[index].mensualite = action.payload.value;

      return state;
    },
    setDurationHouse: (state, action) => {
      const index = action.payload.index;

      state.house[index].duration = action.payload.value;

      return state;
    },
    setLoyerHouse: (state, action) => {
      const index = action.payload.index;

      state.house[index].loyerPercu = action.payload.value;

      return state;
    },
    deleteHouse: (state, action) => {
      const index = action.payload;

      let newHouse = [];

      for (let i = 0; i < state.house.length; i++) {
        if (i !== index) {
          newHouse.push({
            typeHouse: state.house[i].typeHouse,
            valueAcquisition: state.house[i].valueAcquisition,
            capital: state.house[i].capital,
            mensualite: state.house[i].mensualite,
            duration: state.house[i].duration,
            loyerPercu: state.house[i].loyerPercu,
          });
        }
      }

      state.house = newHouse;
    },
    deleteAllHouse: (state) => {
      delete state.house;
      state.house = [];
    },
  },
});

export const StoreDataRevenusFoyer = createSlice({
  name: "StoreDataRevenusFoyer",
  initialState: {
    revenus: [
      {
        typeRevenus: "activite",
        revenusParAns: "",
        revenusParMois: "",
        revenusParAnsConjoint: "",
        revenusParMoisConjoint: "",
      },
    ],
  },
  reducers: {
    addRevenus: (state, action) => {
      state.revenus.push({
        typeRevenus: "activite",
        revenusParAns: "",
        revenusParMois: "",
        revenusParAnsConjoint: "",
        revenusParMoisConjoint: "",
      });

      return state;
    },
    setTypeRevenus: (state, action) => {
      const index = action.payload.index;

      state.revenus[index].typeRevenus = action.payload.value;

      return state;
    },
    setRevenusParAns: (state, action) => {
      const index = action.payload.index;

      state.revenus[index].revenusParAns = action.payload.value;

      return state;
    },
    setRevenusParMois: (state, action) => {
      const index = action.payload.index;

      state.revenus[index].revenusParMois = action.payload.value;

      return state;
    },
    setRevenusParAnsConjoint: (state, action) => {
      const index = action.payload.index;

      state.revenus[index].revenusParAnsConjoint = action.payload.value;

      return state;
    },
    setRevenusParMoisConjoint: (state, action) => {
      const index = action.payload.index;

      state.revenus[index].revenusParMoisConjoint = action.payload.value;

      return state;
    },
    deleteRevenus: (state, action) => {
      const index = action.payload;

      let newRevenus = [];

      for (let i = 0; i < state.revenus.length; i++) {
        if (i !== index) {
          newRevenus.push({
            typeRevenus: state.revenus[i].typeRevenus,
            revenusParAns: state.revenus[i].revenusParAns,
            revenusParMois: state.revenus[i].revenusParMois,
            revenusParAnsConjoint: state.revenus[i].revenusParAnsConjoint,
            revenusParMoisConjoint: state.revenus[i].revenusParMoisConjoint,
          });
        }
      }

      state.revenus = newRevenus;
    },
    deleteAllRevenus: (state) => {
      delete state.revenus;
      state.revenus = [];
    },
  },
});

export const StoreDataEpargne = createSlice({
  name: "StoreDataEpargne",
  initialState: {
    epargne: [
      {
        typeEpargne: "Produit d'epargne",
        capitalConstitue: "",
        mensualite: "",
        assureur: "Gan",
        butRecherche: "But recherche",
      },
    ],
  },
  reducers: {
    addEpargne: (state) => {
      state.epargne.push({
        typeEpargne: "Autre",
        capitalConstitue: "",
        mensualite: "",
        assureur: "Autre",
        butRecherche: "Retraite",
      });

      return state;
    },
    setTypeEpargne: (state, action) => {
      const index = action.payload.index;

      if (action.payload.value === "Compte_titre_pea") action.payload.value = "CompteTitrePEA";
      if (action.payload.value === "LE") action.payload.value = "LEP";

      state.epargne[index].typeEpargne = action.payload.value;

      return state;
    },
    setCapitalConstitueEpargne: (state, action) => {
      const index = action.payload.index;

      state.epargne[index].capitalConstitue = action.payload.value;

      return state;
    },
    setMensualiteEpargne: (state, action) => {
      const index = action.payload.index;

      state.epargne[index].mensualite = action.payload.value;

      return state;
    },
    setAssureurEpargne: (state, action) => {
      const index = action.payload.index;

      state.epargne[index].assureur = action.payload.value;

      return state;
    },
    setButRechercheEpargne: (state, action) => {
      const index = action.payload.index;

      state.epargne[index].butRecherche = action.payload.value;

      return state;
    },
    deleteEpargne: (state, action) => {
      const index = action.payload;

      let newEpargne = [];

      for (let i = 0; i < state.epargne.length; i++) {
        if (i !== index) {
          newEpargne.push({
            typeEpargne: state.epargne[i].typeEpargne,
            capitalConstitue: state.epargne[i].capitalConstitue,
            mensualite: state.epargne[i].mensualite,
            assureur: state.epargne[i].assureur,
            butRecherche: state.epargne[i].butRecherche,
          });
        }
      }

      state.epargne = newEpargne;
    },
    deleteAllEpargne: (state) => {
      delete state.epargne;
      state.epargne = [];
    },
  },
});

export const StoreDataProtection = createSlice({
  name: "StoreDataProtection",
  initialState: {
    protection: [
      {
        typeProtection: "Produit de protection",
        mensualite: "",
        assureur: "AFI-ESCA",
      },
    ],
  },
  reducers: {
    addProtection: (state, action) => {
      state.protection.push({
        typeProtection: "Produit de protection",
        mensualite: "",
        assureur: "AFI-ESCA",
      });

      return state;
    },
    setProduitProtection: (state, action) => {
      const index = action.payload.index;

      state.protection[index].typeProtection = action.payload.value;

      return state;
    },
    setMensualiteProtection: (state, action) => {
      const index = action.payload.index;

      state.protection[index].mensualite = action.payload.value;

      return state;
    },
    setAssureurProtection: (state, action) => {
      const index = action.payload.index;

      state.protection[index].assureur = action.payload.value;

      return state;
    },
    deleteProtection: (state, action) => {
      const index = action.payload;

      let newProtection = [];

      for (let i = 0; i < state.protection.length; i++) {
        if (i !== index) {
          newProtection.push({
            typeProtection: state.protection[i].typeProtection,
            mensualite: state.protection[i].mensualite,
            assureur: state.protection[i].assureur,
          });
        }
      }

      state.protection = newProtection;
    },
    deleteAllProtection: (state) => {
      delete state.protection;
      state.protection = [];
    },
  },
});

export const StoreDataChargesFoyer = createSlice({
  name: "StoreDataChargesFoyer",
  initialState: {
    charges: [
      {
        typeCharges: "loyer",
        mensualite: "",
        mensualiteConjoint: "",
      },
    ],
  },
  reducers: {
    addCharge: (state, action) => {
      state.charges.push({
        typeCharges: "loyer",
        mensualite: "",
        mensualiteConjoint: "",
      });

      return state;
    },
    setTypeCharge: (state, action) => {
      const index = action.payload.index;

      state.charges[index].typeCharges = action.payload.value;

      return state;
    },
    setMensualiteCharge: (state, action) => {
      const index = action.payload.index;

      state.charges[index].mensualite = action.payload.value;

      return state;
    },
    setMensualiteConjointCharge: (state, action) => {
      const index = action.payload.index;

      state.charges[index].mensualiteConjoint = action.payload.value;

      return state;
    },
    deleteCharges: (state, action) => {
      const index = action.payload;

      let newCharges = [];

      for (let i = 0; i < state.charges.length; i++) {
        if (i !== index) {
          newCharges.push({
            typeCharges: state.charges[i].typeCharges,
            mensualite: state.charges[i].mensualite,
            mensualiteConjoint: state.charges[i].mensualiteConjoint,
          });
        }
      }

      state.charges = newCharges;
    },
    deleteAllCharges: (state) => {
      delete state.charges;
      state.charges = [];
    },
  },
});

export const StoreDataObjectifsPatrimoniaux = createSlice({
  name: "StoreDataObjectifsPatrimoniaux",
  initialState: {
    preparerRetraite: false,
    constituerPatrimoine: false,
    optimiserFiscalite: false,
    protectionDead: false,
    constituerCapital: false,
    optimiserTransmissionPatrimoine: false,
    protegerProche: false,
    protegerSante: false,
    nationaliteUS: false,
    residentFiscalUS: false,
    numeroFiscalUS: "",
    exerceFonctionPolitique: false,
    fonctionExerce: "",
    dateFonctionExerce: "",
    familleOuEntourage: false,
    lienPersonne: "",
    fonctionExerceFriend: "",
    origineDesFondsInvestis: "Revenus Activité",
  },
  reducers: {
    setPreparerRetraite: (state, action) => {
      state.preparerRetraite = action.payload;
    },
    setConstituerPatrimoine: (state, action) => {
      state.constituerPatrimoine = action.payload;
    },
    setOptimiserFiscalite: (state, action) => {
      state.optimiserFiscalite = action.payload;
    },
    setProtectionDead: (state, action) => {
      state.protectionDead = action.payload;
    },
    setConstituerCapital: (state, action) => {
      state.constituerCapital = action.payload;
    },
    setOptimiserTransmissionPatrimoine: (state, action) => {
      state.optimiserTransmissionPatrimoine = action.payload;
    },
    setProtegerProche: (state, action) => {
      state.protegerProche = action.payload;
    },
    setProtegerSante: (state, action) => {
      state.protegerSante = action.payload;
    },
    setNationaliteUS: (state, action) => {
      state.nationaliteUS = action.payload;
    },
    setResidentFiscalUS: (state, action) => {
      state.residentFiscalUS = action.payload;
    },
    setNumeroFiscalUS: (state, action) => {
      state.numeroFiscalUS = action.payload;
    },
    setExerceFonctionPolitique: (state, action) => {
      state.exerceFonctionPolitique = action.payload;
    },
    setFonctionExerce: (state, action) => {
      state.fonctionExerce = action.payload;
    },
    setDateFonctionExerce: (state, action) => {
      state.dateFonctionExerce = action.payload;
    },
    setFamilleOuEntourage: (state, action) => {
      state.familleOuEntourage = action.payload;
    },
    setLienPersonne: (state, action) => {
      state.lienPersonne = action.payload;
    },
    setFonctionExerceFriend: (state, action) => {
      state.fonctionExerceFriend = action.payload;
    },
    setOrigineDesFondsInvestis: (state, action) => {
      state.origineDesFondsInvestis = action.payload;
    },
  },
});

export const StoreDataProduitsDispo = createSlice({
  name: "StoreDataProduitsDispo",
  initialState: {
    planEpargneRetraite: true,
    assuranceVie: false,
    assuranceEmprunteur: false,
    prevoyancePro: false,
    immobilierPinel: false,
    mutuelle: false,
    mensualiteEpargneRetraite: "",
    ageDepartEnRetraite: 65,
    mensualiteAssuranceVie: "",
    versementLibreAssuranceVie: "",
    profilInvestissement: "Équilibré",
    produitStructureAssuranceVie: "non",
    economieImpots: "Oui",
    mensualiteAssuranceEmprunteur: "",
    capitalRestantAssuranceEmprunteur: "",
    dureeAssuranceEmprunteur: "",
    franchisePrevoyancePro: "15/0/3",
    tauxInvaliditePro: "15%",
    mensualitePinel: 0,
    versementLibrePinel: 0,
    economieImpotPinel: "oui",
    mutuelleOptique: 0,
    mutuelleDentaire: 0,
    soinsVille: 0,
    medecineAlternative: "Oui",
  },
  reducers: {
    setProduitStructure: (state, action) => {
      state.produitStructureAssuranceVie = action.payload;
    },
    setAgeDepartRetraite: (state, action) => {
      state.ageDepartEnRetraite = action.payload;
    },
    setPlanEpargneRetraite: (state, action) => {
      state.planEpargneRetraite = action.payload;
    },
    setAssuranceVie: (state, action) => {
      state.assuranceVie = action.payload;
    },
    setAssuranceEmprunteur: (state, action) => {
      state.assuranceEmprunteur = action.payload;
    },
    setPrevoyancePro: (state, action) => {
      state.prevoyancePro = action.payload;
    },
    setImmobilierPinel: (state, action) => {
      state.immobilierPinel = action.payload;
    },
    setMutuelle: (state, action) => {
      state.mutuelle = action.payload;
    },
    setMensualiteEpargneRetraite: (state, action) => {
      state.mensualiteEpargneRetraite = action.payload;
    },
    setMensualiteAssuranceVie: (state, action) => {
      state.mensualiteAssuranceVie = action.payload;
    },
    setVersementLibreAssuranceVie: (state, action) => {
      state.versementLibreAssuranceVie = action.payload;
    },
    setProfilInvestissement: (state, action) => {
      state.profilInvestissement = action.payload;
    },
    setEconomieImpots: (state, action) => {
      state.economieImpots = action.payload;
    },
    setMensualiteAssuranceEmprunteur: (state, action) => {
      state.mensualiteAssuranceEmprunteur = action.payload;
    },
    setCapitalRestantAssuranceEmprunteur: (state, action) => {
      state.capitalRestantAssuranceEmprunteur = action.payload;
    },
    setDureeAssuranceEmprunteur: (state, action) => {
      state.dureeAssuranceEmprunteur = action.payload;
    },
    setFranchisePrevoyancePro: (state, action) => {
      state.franchisePrevoyancePro = action.payload;
    },
    setTauxInvaliditePro: (state, action) => {
      state.tauxInvaliditePro = action.payload;
    },
    setMensualitePinel: (state, action) => {
      state.mensualitePinel = action.payload;
    },
    setVersementLibrePinel: (state, action) => {
      state.versementLibrePinel = action.payload;
    },
    setProfilInvestissementPinel: (state, action) => {
      state.profilInvestissementPinel = action.payload;
    },
    setEconomieImpotPinel: (state, action) => {
      state.economieImpotPinel = action.payload;
    },
    setMutuelleOptique: (state, action) => {
      state.mutuelleOptique = action.payload;
    },
    setMutuelleDentaire: (state, action) => {
      state.mutuelleDentaire = action.payload;
    },
    setSoinsVille: (state, action) => {
      state.soinsVille = action.payload;
    },
    setMedecineAlternative: (state, action) => {
      state.medecineAlternative = action.payload;
    },
  },
});

export const StoreDataProfileInvestisseur = createSlice({
  name: "StoreDataProfileInvestisseur",
  initialState: {
    question1: {
      value: "placementA",
      point: 0,
    },
    question2: {
      value: "gestionLibre",
      point: 0,
    },
    question3: {
      value: "pasConnaissances",
      array: {
        connaissance: {
          fondEuros: {
            value: false,
            point: 1,
          },
          opcMonetaires: {
            value: false,
            point: 1,
          },
          opcActions: {
            value: false,
            point: 1,
          },
          opcImmobiliers: {
            value: false,
            point: 1,
          },
          fondsFormule: {
            value: false,
            point: 1,
          },
          defiscalisation: {
            value: false,
            point: 1,
          },
          score: {
            point: 0,
          },
        },
        investis: {
          fondEuros: {
            value: false,
            point: 1,
          },
          opcMonetaires: {
            value: false,
            point: 1,
          },
          opcActions: {
            value: false,
            point: 2,
          },
          opcImmobiliers: {
            value: false,
            point: 2,
          },
          fondsFormule: {
            value: false,
            point: 2,
          },
          defiscalisation: {
            value: false,
            point: 4,
          },
          score: {
            point: 0,
          },
        },
        totaux: {
          scoreTotal: 0,
          scoreTotalMax: 18,
          score: {
            inferieur: false,
            superieur: true,
          },
        },
      },
      point: 0,
    },
    question4: {
      value: "vends",
      point: 0,
    },
    question5: {
      value: "between4And8Years",
      point: 0,
    },
    results: {
      prudent: true,
      equilibre: false,
      dynamique: false,
    },
    date_rdv:
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      "-" +
      (today.getDate() < 10 ? "0" + today.getDate() : today.getDate()),
  },
  reducers: {
    setQuestion1: (state, action) => {
      state.question1.value = action.payload;
    },
    setQuestion1Point: (state, action) => {
      state.question1.point = action.payload;
    },
    setQuestion2: (state, action) => {
      if (action.payload === "gestionMandat") {
        action.payload = "gestionSousMandat";
      }
      state.question2.value = action.payload;
    },
    setQuestion3: (state, action) => {
      state.question3.value = action.payload;
    },
    setQuestion4: (state, action) => {
      state.question4.value = action.payload;
    },
    setQuestion4Point: (state, action) => {
      state.question4.point = action.payload;
    },
    setQuestion5Point: (state, action) => {
      state.question5.point = action.payload;
    },
    setQuestion5: (state, action) => {
      state.question5.value = action.payload;
    },
    setFondsEuroConnaissanc: (state, action) => {
      state.question3.array.connaissance.fondEuros.value = action.payload;
    },
    setScoreConnaissance: (state, action) => {
      state.question3.array.connaissance.score = action.payload;
    },
    setScoreInvestis: (state, action) => {
      state.question3.array.investis.score = action.payload;
    },
    setTotauxScoreInfProfileQ3: (state, action) => {
      state.question3.array.totaux.score.inferieur = action.payload;
    },
    setTotauxScoreSupProfileQ3: (state, action) => {
      state.question3.array.totaux.score.superieur = action.payload;
    },
    setFondsEuroConnaissancePoints: (state, action) => {
      state.question3.array.connaissance.fondEuros.point = action.payload;
    },
    setOpcMonetairesConnaissance: (state, action) => {
      state.question3.array.connaissance.opcMonetaires.value = action.payload;
    },

    setOpcActionsConnaissance: (state, action) => {
      state.question3.array.connaissance.opcActions.value = action.payload;
    },
    setOpcImmobiliersConnaissance: (state, action) => {
      state.question3.array.connaissance.opcImmobiliers.value = action.payload;
    },
    setFondsFormuleConnaissance: (state, action) => {
      state.question3.array.connaissance.fondsFormule.value = action.payload;
    },
    setDefiscalisationConnaissance: (state, action) => {
      state.question3.array.connaissance.defiscalisation.value = action.payload;
    },
    setvalueScoreConnaissance: (state, action) => {
      state.question3.array.connaissance.score.point = action.payload;
    },
    setFondsEuroInvestis: (state, action) => {
      state.question3.array.investis.fondEuros.value = action.payload;
    },
    setOpcMonetairesInvestis: (state, action) => {
      state.question3.array.investis.opcMonetaires.value = action.payload;
    },
    setOpcActionsInvestis: (state, action) => {
      state.question3.array.investis.opcActions.value = action.payload;
    },
    setOpcImmobiliersInvestis: (state, action) => {
      state.question3.array.investis.opcImmobiliers.value = action.payload;
    },
    setFondsFormuleInvestis: (state, action) => {
      state.question3.array.investis.fondsFormule.value = action.payload;
    },
    setDefiscalationInvestis: (state, action) => {
      state.question3.array.investis.defiscalisation.value = action.payload;
    },
    setvalueScoreInvestis: (state, action) => {
      state.question3.array.investis.score.value = action.payload;
    },
    setPointScoreInvestis: (state, action) => {
      state.question3.array.investis.score.point = action.payload;
    },
    setScoreTotal: (state, action) => {
      state.question3.array.totaux.scoreTotal = action.payload;
    },
    setQuestion3Point: (state, action) => {
      state.question3.point = action.payload;
    },
    setDateRdv: (state, action) => {
      state.date_rdv = action.payload;
    },
    setResultsPrudent: (state, action) => {
      state.results.prudent = action.payload;
    },
    setResultsEquilibre: (state, action) => {
      state.results.equilibre = action.payload;
    },
    setResultsDynamique: (state, action) => {
      state.results.dynamique = action.payload;
    }
  },
});

const storeBackend = createSlice({
  name: "storeDataBackend",
  initialState: {
    isDataLoaded: false,
  },
  reducers: {
    setDataLoaded: (state) => {
      state.isDataLoaded = true;
    },
  },
});

export const { setDataLoaded } = storeBackend.actions;

export const {
  setFirstName,
  setLastName,
  setDate,
  setPhoneUser,
  setNbrChildren,
  setStatusMarital,
  setProfession,
  setAdress,
  setSociete,
  setStatutProfessionnel,
  setGender,
  setPostalCode,
  setTown,
  setEmail,
} = StoreData.actions;

export const {
  setConjoint,
  setFirstNameConjoint,
  setLastNameConjoint,
  setDateConjoint,
  setPhoneUserConjoint,
  setEmailConjoint,
  setRegimeMatrimonialConjoint,
  setProfessionConjoint,
  setAdressConjoint,
  setSocieteConjoint,
  setStatusSalarialConjoint,
  setGenderConjoint,
} = StoreDataConjoint.actions;

export const {
  setTypeHouse,
  setAcquisitionHouse,
  setCapitalHouse,
  setMensualiteHouse,
  setDurationHouse,
  addHouse,
  deleteHouse,
  deleteAllHouse,
  setLoyerHouse,
} = StoreDataHouse.actions;

export const {
  setTypeRevenus,
  setRevenusParAns,
  setRevenusParMois,
  setRevenusParAnsConjoint,
  setRevenusParMoisConjoint,
  addRevenus,
  deleteAllRevenus,
  deleteRevenus,
} = StoreDataRevenusFoyer.actions;

export const {
  addCharge,
  deleteAllCharges,
  deleteCharges,
  setMensualiteCharge,
  setMensualiteConjointCharge,
  setTypeCharge,
} = StoreDataChargesFoyer.actions;

export const {
  setPreparerRetraite,
  setConstituerPatrimoine,
  setOptimiserFiscalite,
  setProtectionDead,
  setConstituerCapital,
  setOptimiserTransmissionPatrimoine,
  setProtegerProche,
  setProtegerSante,
  setNationaliteUS,
  setResidentFiscalUS,
  setNumeroFiscalUS,
  setExerceFonctionPolitique,
  setFonctionExerce,
  setDateFonctionExerce,
  setFamilleOuEntourage,
  setLienPersonne,
  setFonctionExerceFriend,
  setOrigineDesFondsInvestis,
} = StoreDataObjectifsPatrimoniaux.actions;

export const {
  setProduitStructure,
  setAgeDepartRetraite,
  setPlanEpargneRetraite,
  setAssuranceVie,
  setAssuranceEmprunteur,
  setPrevoyancePro,
  setImmobilierPinel,
  setMutuelle,
  setMensualiteEpargneRetraite,
  setMensualiteAssuranceVie,
  setVersementLibreAssuranceVie,
  setProfilInvestissement,
  setEconomieImpots,
  setMensualiteAssuranceEmprunteur,
  setCapitalRestantAssuranceEmprunteur,
  setDureeAssuranceEmprunteur,
  setFranchisePrevoyancePro,
  setTauxInvaliditePro,
  setMensualitePinel,
  setVersementLibrePinel,
  setProfilInvestissementPinel,
  setEconomieImpotPinel,
  setMutuelleOptique,
  setMutuelleDentaire,
  setSoinsVille,
  setMedecineAlternative,
} = StoreDataProduitsDispo.actions;

export const {
  setAssureurEpargne,
  setButRechercheEpargne,
  setCapitalConstitueEpargne,
  setMensualiteEpargne,
  setTypeEpargne,
  addEpargne,
  deleteAllEpargne,
  deleteEpargne,
} = StoreDataEpargne.actions;

export const {
  setQuestion1,
  setQuestion2,
  setQuestion3,
  setQuestion3Point,
  setFondsEuroConnaissanc,
  setDefiscalisationConnaissance,
  setOpcMonetairesConnaissance,
  setOpcActionsConnaissance,
  setOpcImmobiliersConnaissance,
  setFondsFormuleConnaissance,
  setFondsEuroInvestis,
  setOpcMonetairesInvestis,
  setOpcActionsInvestis,
  setDefiscalationInvestis,
  setOpcImmobiliersInvestis,
  setFondsFormuleInvestis,
  setQuestion4,
  setQuestion1Point,
  setQuestion4Point,
  setQuestion5Point,
  setQuestion5,
  setTotauxScoreInfProfileQ3,
  setTotauxScoreSupProfileQ3,
  setScoreConnaissance,
  setScoreInvestis,
  setScoreTotal,
  setDateRdv,
  setResultsPrudent,
  setResultsEquilibre,
  setResultsDynamique,
} = StoreDataProfileInvestisseur.actions;
export const {
  addProtection,
  setAssureurProtection,
  setMensualiteProtection,
  setProduitProtection,
  deleteAllProtection,
  deleteProtection,
} = StoreDataProtection.actions;

export const store = configureStore({
  reducer: {
    inputMember: StoreData.reducer,
    inputConjoint: StoreDataConjoint.reducer,
    inputHouse: StoreDataHouse.reducer,
    inputEpargne: StoreDataEpargne.reducer,
    inputProtection: StoreDataProtection.reducer,
    inputRevenus: StoreDataRevenusFoyer.reducer,
    inputChargesFoyer: StoreDataChargesFoyer.reducer,
    inputStoreDataObjectifsPatrimoniaux: StoreDataObjectifsPatrimoniaux.reducer,
    inputStoreDataProduitsDispo: StoreDataProduitsDispo.reducer,
    inputStoreDataProfileInvestisseur: StoreDataProfileInvestisseur.reducer,
    backend: storeBackend.reducer,
  },
});
