import React from "react";
import "./navigation.scss";
import { useNavigate } from "react-router-dom";

export function Navigation(props) {
  let navigate = useNavigate();

  async function handleNext() {
    const a = await props.check();
    console.log(a, "ICI");
    if (a !== false) {
      navigate(props.goto);
    }
    return;
  }

  return (
    <div className={"Container"}>
      <div className={"btn-holder"}>
        <button
          type={"button"}
          className={"Etape-right"}
          onClick={() => navigate(props.goback, { replace: true })}
        >
          Etape Précédente
        </button>
      </div>
      <div className={"btn-start"}>
        <button
          type={"button"}
          className={"Etape-left"}
          onClick={() => handleNext()}
        >
          Etape Suivante
        </button>
      </div>
    </div>
  );
}
