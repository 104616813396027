import React, { useState } from "react";
import { Card } from "../../component/card/card";
import "./actifImmobiliers.scss";
import { Navigation } from "../../component/navigation/navigation";
import { useSelector } from "react-redux";

import { addHouse, deleteHouse } from "../../store/storeData";
import { DynamicArrayImmobilier } from "./dynamicArrayImmobilier";
import { regexRemoveSpaces } from "../../utils/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../component/loader/Loader";
import Alert from "../../component/alert/alert";
import { useEffect } from "react";

export function ActifImmobiliers() {
  const fields = useSelector((state) => state.inputHouse.house);
  const data = useSelector((store) => store.inputHouse);
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get("recordId");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);

  function getPrincipale(string) {
    let a;
    data.house.forEach((elem) => {
      if (elem.typeHouse === string) {
        a = elem;
      }
    });
    return a === undefined
      ? {
          valueAcquisition: "0",
          capital: "0",
          mensualite: "0",
          duration: "Moins d'un ans",
          loyerPercu: "0",
        }
      : a;
  }

  function getSecondaire() {
    const obj = [];
    data.house.forEach((elem) => {
      if (elem.typeHouse === "Résidence Secondaire") obj.push(elem);
    });
    if (obj.length === 0) {
      return {
        valueAcquisition: 0,
        capital: 0,
        mensualite: 0,
        duration: "Moins d'un ans",
        loyerPercu: 0,
      };
    }
    // const aaaa = obj.reduce((accumulator, tkt) => {
    //   console.log(tkt.valueAcquisition);
    //   return accumulator + tkt.valueAcquisition;
    // }, 0);

    return {
      valueAcquisition: obj.reduce((accumulator, object) => {
        return (
          accumulator + parseInt(regexRemoveSpaces(object.valueAcquisition))
        );
      }, 0),
      capital: obj.reduce((accumulator, object) => {
        return accumulator + parseInt(regexRemoveSpaces(object.capital));
      }, 0),
      mensualite: obj.reduce((accumulator, object) => {
        return accumulator + parseInt(regexRemoveSpaces(object.mensualite));
      }, 0),
      duration: obj[0].duration,
    };
  }

  function checkData() {
    let principale = getPrincipale("Résidence Principale");
    let sec = getSecondaire();
    const obj = {
      principale: {
        capital_restant_du: parseInt(regexRemoveSpaces(principale.capital)),
        valeur_dacquisition: parseInt(
          regexRemoveSpaces(principale.valueAcquisition)
        ),
        mensualites_pret: parseInt(regexRemoveSpaces(principale.mensualite)),
        duree_restante: principale.duration,
      },
      secondaire: {
        capital_restant_du: sec.capital,
        valeur_dacquisition: sec.valueAcquisition,
        mensualites_pret: sec.mensualite,
        duree_restante: sec.duration,
      },
      locatif: [],
    };
    data.house.forEach((elem) => {
      if (elem.typeHouse === "Immobilier Locatif") {
        obj.locatif.push({
          capital_restant_du: parseInt(regexRemoveSpaces(elem.capital)),
          valeur_dacquisition: parseInt(
            regexRemoveSpaces(elem.valueAcquisition)
          ),
          mensualites_pret: parseInt(regexRemoveSpaces(elem.mensualite)),
          duree_restante: elem.duration,
          loyer_par_mois: parseInt(regexRemoveSpaces(elem.loyerPercu)),
        });
      }
    });
    return obj;
  }

  async function SendData() {
    const body = checkData();
    setLoader(true);
    try {
      const res = await fetch(
        `https://vamj7s8f82.execute-api.eu-west-3.amazonaws.com/prod/sf-request/2?recordId=${recordId}`,
        {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!res.ok) {
        const a = await res.text();
        setError(true);
        setLoader(false);
        setErrors(JSON.parse(a).message);

        setTimeout(() => {
          setError(false);
        }, 7000);

        return false;
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  }

  const dataIsLoaded = useSelector((state) => state.backend.isDataLoaded);
  const navigate = useNavigate();

  useEffect(() => {
    if (!dataIsLoaded || recordId === undefined || recordId === "" || searchParams.get("securityToken") === undefined || searchParams.get("securityToken") === "") {
      const interval = setInterval(() => {
        navigate(`/?recordId=${recordId}&securityToken=${searchParams.get("securityToken")}`, { replace: true })
      }, 200);

      return function () {
        clearInterval(interval);
      }
        
    }
  }, [dataIsLoaded, navigate, recordId, searchParams]);

  return (
    <div className="actif-immobilier">
      <Alert isActive={error} errors={errors} />
      <Loader isActive={loader} />
      <div className={"page-format"}>
        <div>
          <h1>Detaillons vos Actifs immobiliers 🏠</h1>
        </div>
        <Card>
          <div className={"ActifImmos"}>
            <DynamicArrayImmobilier
              create={"Ajouter un Actif Immobilier"}
              delete={"Effacer tous les Actifs Immobiliers"}
              fields={fields}
              addValueReducer={addHouse}
              removeValueReducer={deleteHouse}
            ></DynamicArrayImmobilier>
            <div className={"Manage"}></div>
          </div>
        </Card>
      </div>
      <Navigation
        goto={`/Epargne?recordId=${recordId}&securityToken=${searchParams.get(
          "securityToken"
        )}`}
        goback={`/StatusMembers?recordId=${recordId}&securityToken=${searchParams.get(
          "securityToken"
        )}`}
        check={SendData}
      />
    </div>
  );
}
