import { Card } from "../../component/card/card";
import "./remerciement.scss";

export function Remerciement() {
  return (
    <div>
      <div className={"page-format"}>
        <div className="goodbyeBody">
          <h1>Merci de nous avoir accordé votre temps</h1>
          <Card>
            <div className="goodbyeText">
              <p style={{ "text-align": "center" }}>
                Nous espérons que vous avez été satisfait par ce rendez-vous.
                <br />
                Vos données ont bien été saisies, elle nous permettront de
                dresser le meilleur bilan conseil vous concernant.
                <br />
                Chez Lexem nous avons pour priorité de vous proposer le meilleur
                conseil financier.
              </p>
            </div>
          </Card>
        </div>
        <Card>
          <div className="goodbyeText">
            <p style={{ "text-align": "center" }}>
              Nous espérons que vous avez été satisfait par ce rendez-vous.
              <br />
              Vos données ont bien été saisies, elle nous permettront de dresser
              le meilleur bilan conseil vous concernant.
              <br />
              Chez Lexem nous avons pour priorité de vous proposer le meilleur
              conseil financier.
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
}
