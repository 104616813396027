import React, { useEffect, useState } from "react";
import { Card } from "../../component/card/card";
import { Navigation } from "../../component/navigation/navigation";
import { DynamicArrayFoyer } from "./dynamicArrayFoyer";
import { addRevenus, deleteRevenus } from "../../store/storeData";
import { useSelector } from "react-redux";
import { regexRemoveSpaces } from "../../utils/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../component/loader/Loader";
import Alert from "../../component/alert/alert";
import "./revenusFoyer.scss";

export function RevenusFoyer() {
  const fields = useSelector((state) => state.inputRevenus.revenus);
  const data = useSelector((store) => store.inputRevenus);
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get("recordId");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);

  function getRevenusAnnuel(revenus, who) {
    let result = 0;

    for (let i = 0; i < data.revenus.length; ++i) {
      if (data.revenus[i].typeRevenus === revenus && who === "vous") {
        result += parseInt(regexRemoveSpaces(data.revenus[i].revenusParAns));
      } else if (data.revenus[i].typeRevenus === revenus && who === "conjoint")
        result += parseInt(
          regexRemoveSpaces(data.revenus[i].revenusParAnsConjoint)
        );
    }
    return result;
  }

  function getRevenusMensuel(revenus, who) {
    let result = 0;

    for (let i = 0; i < data.revenus.length; ++i) {
      if (data.revenus[i].typeRevenus === revenus && who === "vous") {
        result += parseInt(regexRemoveSpaces(data.revenus[i].revenusParMois));
      } else if (data.revenus[i].typeRevenus === revenus && who === "conjoint")
        result += parseInt(
          regexRemoveSpaces(data.revenus[i].revenusParMoisConjoint)
        );
    }
    return result;
  }

  function getYourRevenus(who) {
    const obj = [
      {
        type: "activite",
        annuel: getRevenusAnnuel("activite", who),
        mensuel: getRevenusMensuel("activite", who),
      },
      {
        type: "mobilier",
        annuel: getRevenusAnnuel("mobilier", who),
        mensuel: getRevenusMensuel("mobilier", who),
      },
      {
        type: "immobilier",
        annuel: getRevenusAnnuel("immobilier", who),
        mensuel: getRevenusMensuel("immobilier", who),
      },
    ];
    return obj;
  }

  function ConvertData() {
    const obj = {
      vous: getYourRevenus("vous"),
      conjoint: getYourRevenus("conjoint"),
    };

    return obj;
  }

  async function SendData() {
    setLoader(true);
    try {
      const res = await fetch(
        `https://vamj7s8f82.execute-api.eu-west-3.amazonaws.com/prod/sf-request/4?recordId=${recordId}`,
        {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(ConvertData()),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!res.ok) {
        const a = await res.text();
        setErrors(JSON.parse(a).message);

        setError(true);

        setTimeout(() => {
          setError(false);
        }, 7000);

        setLoader(false);
        return false;
      }

      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  }

  const dataIsLoaded = useSelector((state) => state.backend.isDataLoaded);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !dataIsLoaded ||
      recordId === undefined ||
      recordId === "" ||
      searchParams.get("securityToken") === undefined ||
      searchParams.get("securityToken") === ""
    ) {
      const interval = setInterval(() => {
        navigate(
          `/?recordId=${recordId}&securityToken=${searchParams.get(
            "securityToken"
          )}`,
          { replace: true }
        );
      }, 200);

      return function () {
        clearInterval(interval);
      };
    }
  }, [dataIsLoaded, navigate, recordId, searchParams]);

  return (
    <div className="revenus-foyer">
      <Loader isActive={loader} />
      <Alert isActive={error} errors={errors} />
      <div className={"page-format"}>
        <div>
          <h1>Détaillons les revenus de votre foyer 🤑</h1>
        </div>
        <div>
          <Card>
            <DynamicArrayFoyer
              create={"Ajouter une source de revenus"}
              delete={"Effacer toute les sources de revenus"}
              fields={fields}
              addValueReducer={addRevenus}
              removeValueReducer={deleteRevenus}
            ></DynamicArrayFoyer>
          </Card>
        </div>
      </div>
      <Navigation
        goto={`/ChargeFoyer?recordId=${recordId}&securityToken=${searchParams.get(
          "securityToken"
        )}`}
        goback={`/Epargne?recordId=${recordId}&securityToken=${searchParams.get(
          "securityToken"
        )}`}
        check={SendData}
      />
    </div>
  );
}
