export function putSpaces(number, n) {
    var numberString;
    if (typeof number == "string") {
      number = number.replaceAll(" ", "");
      numberString = number;
    } else {
      numberString = number.toString();
    }
  
    if (!isNaN(numberString)) {
      let toReturn = "";
      for (let i = 0; i < numberString.length; i++) {
        if (i % n === 0 && i !== 0) {
          toReturn += " ";
        }
  
        toReturn += numberString[numberString.length - 1 - i];
      }
  
      return toReturn.split("").reverse().join("");
    }
  
    return "";
  }

export function regexRemoveSpaces(value) {
  return value.replace(/\D+/g, "") === "" ? "0" : value.replace(/\D+/g, "");
}

export function prixDuBienAndMensualitePinel(income, fee, dureeEmprunt, TAEG){
  let capaciteRemboursement = (income - fee) * 0.35 * 12 * dureeEmprunt;
  const defaultCapacity = capaciteRemboursement

  let montantRestantDu = capaciteRemboursement
  let montantFrais = 0

  for(let i = 0; i < dureeEmprunt * 12; i++){
    montantFrais += montantRestantDu * (TAEG/12)
    montantRestantDu -= capaciteRemboursement / dureeEmprunt / 12
  }

  const prixDuBien = defaultCapacity - montantFrais - 600;

  return [prixDuBien, defaultCapacity/12/dureeEmprunt];
}

export function capitalTermePer(versement, taux, age, ageDepartRetraite){

}

export function plafondDefisc(activity, income){
 
  const annualIncome = income * 12;

  if(activity === "TNS (SSI, ex RSI)"){
      if(annualIncome < 41136){
          return Math.round((0.10 * 41136) / 12);
      }else{
          return Math.round(((0.10 * 41136) + (0.15 * (annualIncome - 41136))) / 12);
      }
  }else{
      if(annualIncome < 41136){
          return Math.round((0.10 * 41136) / 12);
      }else{
          return Math.round((0.10 * annualIncome) / 12);
      }
  }
}

export function getCapitalPer(versementMonthly, versementInitial, age, interest_rate, ageDepartRetraite){
 
  var versementAnnual = versementMonthly * 12;
  var capitalTerme = (versementAnnual + versementInitial) * (1 + interest_rate);
  var versementEvolution = [Math.round(versementAnnual + versementInitial)];
  var interestEvolution = [Math.round(versementEvolution[0] * interest_rate)];
  var capitalEvolution = [Math.round(versementEvolution[0] + interestEvolution[0])];
  var periodSaving = ageDepartRetraite - age;

  for (var i = 1; i < periodSaving; i++){
      interestEvolution.push(Math.round(interestEvolution[interestEvolution.length - 1] + ((capitalTerme + versementAnnual) * interest_rate)));
      capitalTerme = Math.round((capitalTerme + versementAnnual) * (1 + interest_rate));
      versementEvolution.push(Math.round(versementEvolution[versementEvolution.length - 1] + versementAnnual));
      capitalEvolution.push(Math.round(versementEvolution[versementEvolution.length - 1] + interestEvolution[interestEvolution.length - 1]));
  }

  return {capitalTerme : Math.round(capitalTerme), capitalEvolution : capitalEvolution, versmentEvolution : versementEvolution, interestEvolution: interestEvolution};
}

export function nbrPart(situation, kids){
  if(situation === "Célibataire" || situation === "Divorcé" || situation === "En concubinage"){
      if(kids === 0){
          return 1;
      }else if(kids === 1){
          return 1.5;
      }else if(kids >= 2){
          return kids;
      }

  }else if(situation === "Marié" || situation === "Pacsé" || situation === "Veuf.ve"){
      if (kids === 0){
          return 2;
      }else if(kids === 1){
          return 2.5;
      }else if(kids >= 2){
          return kids + 1;
      }
  }
}

// Returns tranche marginale d'imposition
export function tmi(income, nbrPart){
  income = parseInt(income)*12/nbrPart;
  if(income <= 10084){
      return 0;
 }else if(income >= 10085 && income <= 25710){
      return 11;
  }else if(income >= 25711 && income <= 73516){
      return 30;
  }else if(income >= 73517 && income <= 158122){
      return 41;
  }else if(income > 158122){
      return 45;
  }
}


// Economie d'impôts PER
export function ecoImpotsPer(activity, myTmi, income, versement){
 
  return Math.round(
      Math.min(plafondDefisc(activity, income) * 12 * myTmi/100, versement * 12 * myTmi/100)
  );
}