import React from "react";
import "./myInput.scss";

export function MyInput(props) {
  return (
    <>
      {props.obligatoire ? (
        <label htmlFor="first" className={"obligatoire"}>
          *
        </label>
      ) : (
        <div></div>
      )}
      <input
        name="first"
        className={"Input " + props.className}
        placeholder={props.placeholder}
        type={props.type}
        id={props.id}
        onChange={props.onChange}
        value={props.value}
      />
    </>
  );
}

//        {...props.registerFunc(props.tmp)}
