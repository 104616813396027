import React from "react";
import "../alert/alert.scss";

export default function Alert(props) {
  let msg = "";
  let tmp = [];
  if (typeof props.errors === "string" || props.errors instanceof String) {
    msg = props.errors;
  } else {
    props.errors.forEach((element) => {
      tmp.push(element.split(" ")[0]);
      msg = "Veuillez remplir tous les champs obligatoires: " + tmp.join("\n");
    });
  }
  return (
    <div className={"alert" + (props.isActive ? " enabled" : " disabled")}>
      {msg}
    </div>
  );
}
