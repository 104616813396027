import './App.css';
import {Routes, Route} from "react-router-dom";
import {StatusMembers} from "./pages/members/statusMembers";
import {ActifImmobiliers} from "./pages/immobilier/actifImmobiliers";
import {Epargne} from "./pages/epargne/epargne";
import {NavBar} from "./component/navBar/navBar";
import {ChargesFoyer} from "./pages/chargesFoyer/chargesFoyer";
import {RevenusFoyer} from "./pages/foyer/revenusFoyer";
import {ResumeSituation} from "./pages/resumeSituation/resumeSituation";
import {ObjectifsPatrimoniaux} from "./pages/objectifsPatrimoniaux/objectifsPatrimoniaux";
import {Produitsdispo} from "./pages/produitsdispo/produitsdispo";
import {ProfileInvestisseur} from "./pages/profileInvestisseur/profileInvestisseur";
import {Remerciement} from "./pages/remerciement/remerciement";
import {Provider} from "react-redux";
import {store} from "./store/storeData";
import {Intro} from "./pages/intro/intro";

function App() {
    return (
        <Provider store={store}>
            <div className="App">
                <NavBar/>
                <Routes>
                    <Route path="/" element={<StatusMembers/>}/>
                    <Route path="/Intro" element={<Intro/>}/>
                    <Route path="/StatusMembers" element={<StatusMembers/>}/>
                    <Route path="/ActifImmobiliers" element={<ActifImmobiliers/>}/>
                    <Route path="/Epargne" element={<Epargne/>}/>
                    <Route path="/ChargeFoyer" element={<ChargesFoyer/>}/>
                    <Route path="/RevenusFoyer" element={<RevenusFoyer/>}/>
                    <Route path="/ResumeSituation" element={<ResumeSituation/>}/>
                    <Route path="/ObjectifsPatrimoniaux" element={<ObjectifsPatrimoniaux/>}/>
                    <Route path="/Produitsdispo" element={<Produitsdispo/>}/>
                    <Route path={"/ProfileInvestisseur"} element={<ProfileInvestisseur/>}/>
                    <Route path={"/Remerciement"} element={<Remerciement/>}/>
                </Routes>
            </div>
        </Provider>
    );
}

export default App;
