import "./dynamicArrayImmobilier.scss";
import { Button } from "../../component/button/button";
import { useDispatch } from "react-redux";
import { DropDown } from "../../component/dropDown/dropDown";
import { MyInput } from "../../component/input/myInput";
import {
  deleteAllHouse,
  deleteHouse,
  setAcquisitionHouse,
  setCapitalHouse,
  setDurationHouse,
  setLoyerHouse,
  setMensualiteHouse,
  setTypeHouse,
} from "../../store/storeData";
import { putSpaces } from "../../utils/functions";

export function DynamicArrayImmobilier(props) {
  const dispatch = useDispatch();

  const addCard = () => {
    dispatch(props.addValueReducer());
  };

  // Construction du tableau

  let table = [];
  for (let i = 0; i < props.fields.length; i++) {
    table.push(
      <div key={i} className={i}>
        <div className={"Data"}>
          <div className={"Data"}>
            <div className={"Actif"}>
              <div className={"drop"}>
                <DropDown id={"MyDrop"}>
                  <select
                    name="Type House"
                    className="type-house"
                    id="lang"
                    value={props.fields[i].typeHouse}
                    onChange={(e) => {
                      dispatch(
                        setTypeHouse({
                          value: e.target.value,
                          index: i,
                        })
                      );
                    }}
                  >
                      <option value="">Type Résidence</option>
                      <option value="Résidence Principale">
                      Résidence Principale
                    </option>
                    <option value="Résidence Secondaire">
                      Résidence Secondaire
                    </option>
                    <option value="Immobilier Locatif">
                      Immobilier Locatif
                    </option>
                  </select>
                </DropDown>
              </div>
              <MyInput
                placeholder="Valeur d'acquisition"
                className="TextBox value-acquisition"
                type="text"
                value={props.fields[i].valueAcquisition}
                id="MyInput"
                onChange={(e) => {
                  dispatch(
                    setAcquisitionHouse({
                      value: putSpaces(e.target.value, 3),
                      index: i,
                    })
                  );
                }}
              />
              <MyInput
                placeholder="Capital restant dû"
                className="TextBox capital"
                type="text"
                id="MyInput"
                value={props.fields[i].capital}
                onChange={(e) => {
                  dispatch(
                    setCapitalHouse({
                      value: putSpaces(e.target.value, 3),
                      index: i,
                    })
                  );
                }}
              />
              <MyInput
                placeholder="Mensualités prêt"
                className="TextBox mensualite"
                type="text"
                id="MyInput"
                value={props.fields[i].mensualite}
                onChange={(e) => {
                  dispatch(
                    setMensualiteHouse({
                      value: putSpaces(e.target.value, 3),
                      index: i,
                    })
                  );
                }}
              />
              <DropDown id={"MyDrop"}>
                <select
                  name="duree restante"
                  id="lang"
                  className="duree"
                  value={props.fields[i].duration}
                  onChange={(e) => {
                    dispatch(
                      setDurationHouse({
                        value: e.target.value,
                        index: i,
                      })
                    );
                  }}
                >
                  <option value="">Durée restante</option>
                  <option value="Moins d'un ans">Moins d'un an</option>
                  <option value="1 et 5 ans">1 et 5 ans</option>
                  <option value="5 et 10 ans">5 et 10 ans</option>
                  <option value="10 ans et plus">10 ans et plus</option>
                </select>
              </DropDown>
              {props.fields[i].typeHouse === "Immobilier Locatif" ? (
                <MyInput
                  placeholder="Loyer Perçu"
                  className="TextBox loyer"
                  type="text"
                  id="MyInput"
                  value={props.fields[i].loyerPercu}
                  onChange={(e) => {
                    dispatch(
                      setLoyerHouse({
                        value: putSpaces(e.target.value, 3),
                        index: i,
                      })
                    );
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={"Delete"}>
            <div
              className={"DeleteIcon"}
              onClick={() => {
                dispatch(deleteHouse(i));
              }}
            >
              🗑
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={"Data"}></div>
      <form onSubmit={(e) => {}}>
        {table}
        {/* {props.fields.map((field, index) => {
          return (
            
          );
        })} */}
      </form>
      <div className={"Manage"}>
        <div className={"AddingButton"}>
          <Button
            onClick={(e) => {
              addCard();
            }}
            className={"MyButton"}
          >
            {props.create}
          </Button>
        </div>
        <div>
          <Button
            onClick={() => {
              dispatch(deleteAllHouse());
            }}
            className={"MyButton"}
          >
            {props.delete}
          </Button>
        </div>
      </div>
    </div>
  );
}
