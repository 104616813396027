import React, { useEffect } from "react";
import { Card } from "../../component/card/card";
import { MyInput } from "../../component/input/myInput";
import "./objectifsPatrimoniaux.scss";
import { DropDown } from "../../component/dropDown/dropDown";
import { useSelector } from "react-redux";
import { Navigation } from "../../component/navigation/navigation";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setConstituerCapital,
  setConstituerPatrimoine,
  setDateFonctionExerce,
  setExerceFonctionPolitique,
  setFamilleOuEntourage,
  setFonctionExerce,
  setFonctionExerceFriend,
  setLienPersonne,
  setNationaliteUS,
  setNumeroFiscalUS,
  setOptimiserFiscalite,
  setOptimiserTransmissionPatrimoine,
  setOrigineDesFondsInvestis,
  setPreparerRetraite,
  setProtectionDead,
  setProtegerProche,
  setProtegerSante,
  setResidentFiscalUS,
} from "../../store/storeData";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../component/loader/Loader";
import Alert from "../../component/alert/alert";

export function ObjectifsPatrimoniaux() {
  const data = useSelector(
    (store) => store.inputStoreDataObjectifsPatrimoniaux
  );

  const [ssn, setSsn] = useState(false);
  const [american, setAmerican] = useState(undefined);
  const [fct, setFct] = useState(undefined);
  const [proche, setProche] = useState(undefined);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);

  let prepareRetraite = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.preparerRetraite
  );
  let optimiserFiscalite = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.optimiserFiscalite
  );
  let constituerCapital = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.constituerCapital
  );
  let protegerProche = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.protegerProche
  );
  let constituerPatrimoine = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.constituerPatrimoine
  );
  let protectionDead = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.protectionDead
  );
  let optimiserTransmissionPatrimoine = useSelector(
    (state) =>
      state.inputStoreDataObjectifsPatrimoniaux.optimiserTransmissionPatrimoine
  );
  let protegerSante = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.protegerSante
  );
  let nationaliteUS = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.nationaliteUS
  );
  let residentFiscal = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.residentFiscalUS
  );
  let exerceFonctionPolitique = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.exerceFonctionPolitique
  );
  let fonctionExerce = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.fonctionExerce
  );
  let dateFonctionExerce = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.dateFonctionExerce
  );
  let numeroFiscalUS = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.numeroFiscalUS
  );
  let familleOuEntourage = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.familleOuEntourage
  );
  let fonctionExerceFriend = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.fonctionExerceFriend
  );
  let lienPersonne = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.lienPersonne
  );
  let origineDesFondsInvestis = useSelector(
    (state) => state.inputStoreDataObjectifsPatrimoniaux.origineDesFondsInvestis
  );
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get("recordId");

  function conditionnalDisplay() {
    if (nationaliteUS === true || residentFiscal === true) {
      return (
        <MyInput
          placeholder={"ITIN, SSN, EIN"}
          value={numeroFiscalUS}
          onChange={(e) => {
            dispatch(setNumeroFiscalUS(e.target.value));
          }}
        />
      );
    }
    return;
  }
  function getFct() {
    if (exerceFonctionPolitique === true) {
      return (
        <div style={{ width: "150px", marginRight: "20px" }}>
          <MyInput
            placeholder={"Fonction exercé"}
            value={fonctionExerce}
            className={"fonction-exerce"}
            onChange={(e) => {
              dispatch(setFonctionExerce(e.target.value));
            }}
          />
          <MyInput
            type={"date"}
            className={"date-fonction-exerce"}
            value={dateFonctionExerce}
            onChange={(e) => {
              dispatch(setDateFonctionExerce(e.target.value));
            }}
          />
        </div>
      );
    }
    return;
  }

  function getProche() {
    if (familleOuEntourage === true) {
      return (
        <div>
          <MyInput
            placeholder={"Fonction exercé"}
            value={fonctionExerceFriend}
            onChange={(e) => {
              dispatch(setFonctionExerceFriend(e.target.value));
            }}
          />
          <MyInput
            placeholder={"Lien avec cette personne"}
            value={lienPersonne}
            onChange={(e) => {
              dispatch(setLienPersonne(e.target.value));
            }}
          />
        </div>
      );
    }
    return;
  }

  function ConvertData() {
    const obj = {
      preparer_retraite: data.preparerRetraite,
      opti_fiscalite: data.optimiserFiscalite,
      constituer_capital: data.constituerCapital,
      proteger_proches: data.protegerProche,
      constituer_patrimoine: data.constituerPatrimoine,
      proteger_deces_invalid: data.protectionDead,
      optimiser_transmission_patrimoine: data.optimiserTransmissionPatrimoine,
      proteger_sante: data.protegerSante,
      usa: {
        nationalite_amer: data.nationaliteUS,
        resident_usa: data.residentFiscalUS,
        itin_ssn_ein: data.numeroFiscalUS,
      },
      exerce_fonction_politique: {
        val: data.exerceFonctionPolitique,
        fonction: data.fonctionExerce,
        date: data.dateFonctionExerce,
      },
      proche_fonction_politique: {
        val: data.familleOuEntourage,
        fonction: data.fonctionExerceFriend,
        lien: data.lienPersonne,
      },
      origine_fonds_investis: data.origineDesFondsInvestis,
    };
    return obj;
  }

  async function SendData() {
    setLoader(true);
    const res = await fetch(
      `https://vamj7s8f82.execute-api.eu-west-3.amazonaws.com/prod/sf-request/6?recordId=${recordId}`,
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(ConvertData()),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!res.ok) {
      const a = await res.text();
      console.log(JSON.parse(a).message);

      setError(true);
      setErrors(JSON.parse(a).message);
      setLoader(false);

      setTimeout(() => {
        setError(false);
      }, 7000);

      return false;
    }

    setLoader(false);

    return true;
  }

  const dataIsLoaded = useSelector((state) => state.backend.isDataLoaded);
  const navigate = useNavigate();

  useEffect(() => {
    if (!dataIsLoaded || recordId === undefined || recordId === "" || searchParams.get("securityToken") === undefined || searchParams.get("securityToken") === "") {
      const interval = setInterval(() => {
        navigate(`/?recordId=${recordId}&securityToken=${searchParams.get("securityToken")}`, { replace: true })
      }, 200);

      return function () {
        clearInterval(interval);
      }
        
    }
  }, [dataIsLoaded, navigate, recordId, searchParams]);

  return (
    <div>
      <Loader isActive={loader} />
      <Alert isActive={error} errors={errors} />
      <div className={"page-format"}>
        <div>
          <h1>Définissons vos objectifs patrimoniaux 🎯</h1>
        </div>
        <div className={"format-objectif"}>
          <Card>
            <div className={"check"}>
              <div className={"checking"}>
                <div className={"checker"}>
                  <input
                    type="checkbox"
                    id="Préparer_votre_retraite"
                    name="Préparer_votre_retraite"
                    checked={prepareRetraite}
                    onChange={(e) => {
                      dispatch(setPreparerRetraite(e.target.checked));
                    }}
                  />
                  <label htmlFor="Préparer_votre_retraite">
                    Préparer votre retraite
                  </label>
                </div>
                <div className={"checker"}>
                  <input
                    type="checkbox"
                    id="Optimiser_votre_fiscalité"
                    name="Optimiser_votre_fiscalité"
                    checked={optimiserFiscalite}
                    onChange={(e) => {
                      dispatch(setOptimiserFiscalite(e.target.checked));
                    }}
                  />
                  <label htmlFor="Optimiser_votre_fiscalité">
                    Optimiser votre fiscalité
                  </label>
                </div>
                <div className={"checker"}>
                  <input
                    type="checkbox"
                    id="Vous_constituer_un_capital"
                    name="Vous_constituer_un_capital"
                    checked={constituerCapital}
                    onChange={(e) => {
                      dispatch(setConstituerCapital(e.target.checked));
                    }}
                  />
                  <label htmlFor="Vous_constituer_un_capital">
                    Vous constituer un capital
                  </label>
                </div>
                <div className={"checker"}>
                  <input
                    type="checkbox"
                    id="Protéger_vos_proches"
                    name="Protéger_vos_proches"
                    checked={protegerProche}
                    onChange={(e) => {
                      dispatch(setProtegerProche(e.target.checked));
                    }}
                  />
                  <label htmlFor="Protéger_vos_proches">
                    Protéger vos proches
                  </label>
                </div>
              </div>
              <div className={"checking"}>
                <div className={"checker"}>
                  <input
                    type="checkbox"
                    id="Vous_constituer_un_patrimoine"
                    name="Vous_constituer_un_patrimoine"
                    checked={constituerPatrimoine}
                    onChange={(e) => {
                      dispatch(setConstituerPatrimoine(e.target.checked));
                    }}
                  />
                  <label htmlFor="Vous_constituer_un_patrimoine">
                    Vous constituer un patrimoine
                  </label>
                </div>
                <div className={"checker"}>
                  <input
                    type="checkbox"
                    id="Vous_protéger_en_cas_de_décès"
                    name="Vous_protéger_en_cas_de_décès"
                    checked={protectionDead}
                    onChange={(e) => {
                      dispatch(setProtectionDead(e.target.checked));
                    }}
                  />
                  <label htmlFor="Vous_protéger_en_cas_de_décès">
                    Vous protéger en cas de décès / invalidité
                  </label>
                </div>
                <div className={"checker"}>
                  <input
                    type="checkbox"
                    checked={optimiserTransmissionPatrimoine}
                    id="Optimiser_la_transmission_de_votre_partimoine"
                    name="Optimiser_la_transmission_de_votre_partimoine"
                    onChange={(e) => {
                      dispatch(
                        setOptimiserTransmissionPatrimoine(e.target.checked)
                      );
                    }}
                  />
                  <label htmlFor="Optimiser_la_transmission_de_votre_partimoine">
                    Optimiser la transmission de votre partimoine
                  </label>
                </div>
                <div className={"checker"}>
                  <input
                    type="checkbox"
                    id="Protéger_votre_santé_et/ou_celle_de_vos_proches"
                    name="Protéger_votre_santé_et/ou_celle_de_vos_proches"
                    checked={protegerSante}
                    onChange={(e) => {
                      dispatch(setProtegerSante(e.target.checked));
                    }}
                  />
                  <label htmlFor="Protéger_votre_santé_et/ou_celle_de_vos_proches">
                    Protéger votre santé et/ou celle de vos proches
                  </label>
                </div>
              </div>
            </div>
          </Card>

          <Card>
            <div className={"checking"}>
              <div className={"checker"}>
                <input
                  type="checkbox"
                  id="nat"
                  name="nat"
                  checked={nationaliteUS}
                  onChange={(e) => {
                    setAmerican(!american);
                    dispatch(setNationaliteUS(e.target.checked));
                  }}
                />
                <label htmlFor="nat">
                  Avez-vous la nationnalité américaine ?
                </label>
              </div>
              <div className={"checker"}>
                <input
                  type="checkbox"
                  id="res"
                  name="res"
                  checked={residentFiscal}
                  onChange={(e) => {
                    setSsn(!ssn);
                    dispatch(setResidentFiscalUS(e.target.checked));
                  }}
                />
                <label htmlFor="res">
                  Êtes-vous considéré comme résident à des fins fiscale aux
                  États-Unis ?
                </label>
                {conditionnalDisplay()}
              </div>
            </div>
          </Card>
          <Card>
            <div className={"checking"}>
              <div className={"checker"}>
                <input
                  type="checkbox"
                  id="fonction"
                  name="fonction"
                  checked={exerceFonctionPolitique}
                  onChange={(e) => {
                    setFct(!fct);

                    dispatch(setExerceFonctionPolitique(e.target.checked));
                  }}
                />
                <label htmlFor="fonction">
                  Exercez-vous ou avez-vous exercé depuis moins d'un an une
                  fonction politique, juridictionnelle ou administrative
                  importante ? (Fonction & date de cessation)
                </label>
                {getFct()}
              </div>
              <div className={"checker"}>
                <input
                  type="checkbox"
                  id="friend"
                  name="friend"
                  checked={familleOuEntourage}
                  onChange={(e) => {
                    setProche(!proche);
                    dispatch(setFamilleOuEntourage(e.target.checked));
                  }}
                />
                <label htmlFor="friend">
                  Une personne de votre famille ou de votre entourage proche ?
                  (Fonction & lien avec cette personne)
                </label>
                {getProche()}
              </div>
              <div className={"fond-investis"}>
                <div style={{color: "red"}}>*</div>
                <label htmlFor="fond">Origine des fonds investis</label>
                <DropDown>
                  <select
                    name="FondInvestis"
                    id="lang"
                    value={origineDesFondsInvestis}
                    onChange={(e) => {
                      dispatch(setOrigineDesFondsInvestis(e.target.value));
                    }}
                  >
                    <option value="">Fonds Investis</option>
                    <option value="Revenus d’activité">
                      Revenus d'activité
                    </option>
                    <option value="Héritage">Héritage</option>
                    <option value="Vente d'un bien">Vente D'un Bien</option>
                    <option value="Rachat contrat d'assurance">
                      Rachat contrat d'assurance
                    </option>
                  </select>
                </DropDown>
              </div>
            </div>
          </Card>
        </div>
        <Navigation
          goto={`/Produitsdispo?recordId=${recordId}&securityToken=${searchParams.get(
            "securityToken"
          )}`}
          goback={`/ResumeSituation?recordId=${recordId}&securityToken=${searchParams.get(
            "securityToken"
          )}`}
          check={SendData}
        />
      </div>
    </div>
  );
}
