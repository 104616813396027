import "./dynamicArrayCharges.scss";
import { Button } from "../../component/button/button";
import { useDispatch } from "react-redux";
import { DropDown } from "../../component/dropDown/dropDown";
import { MyInput } from "../../component/input/myInput";
import {
  deleteAllCharges,
  deleteCharges,
  setMensualiteCharge,
  setTypeCharge,
} from "../../store/storeData";
import { putSpaces } from "../../utils/functions";

export function DynamicArrayCharges(props) {
  const dispatch = useDispatch();

  const addCard = () => {
    dispatch(props.addValueReducer());
  };

  let table = [];
  for (let i = 0; i < props.fields.length; i++) {
    table.push(
      <div className="ChargesInput" key={i}>
        <div className={"Actif"}>
          <div style={{color: "red"}}>*</div>
          <div className={"drop"}>
            <DropDown>
              <select
                name="Charges Foyer"
                id="lang"
                value={props.fields[i].typeCharges}
                onChange={(e) => {
                  dispatch(setTypeCharge({ value: e.target.value, index: i }));
                }}
              >
                <option value="">Charges Foyer</option>
                <option value="loyer">Loyer</option>
                <option value="credit_conso">Crédit consommation</option>
                <option value="credit_auto">Crédit auto</option>
                <option value="impot_revenu">Împot sur le revenu</option>
                <option value="taxe_fonciere">Taxe foncière</option>
                <option value="pension_alimentaire">Pension alimentaire</option>
                <option value="autres">Autres charges</option>
              </select>
            </DropDown>
          </div>
          <MyInput
              obligatoire={true}
              placeholder="Mensualité "
            className="TextBox"
            type="text"
            id="MyInput"
            value={props.fields[i].mensualite}
            onChange={(e) => {
              dispatch(
                setMensualiteCharge({
                  value: putSpaces(e.target.value, 3),
                  index: i,
                })
              );
            }}
          />

          <div className={"Delete"}>
            <div
              className={"DeleteIcon"}
              onClick={() => {
                dispatch(deleteCharges(i));
              }}
            >
              🗑
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={"Data"}></div>
      <form onSubmit={(e) => {}}>{table}</form>
      <div className={"Manage"}>
        <div className={"AddingButton"}>
          <Button
            onClick={(e) => {
              addCard();
            }}
            className={"MyButton"}
          >
            {props.create}
          </Button>
        </div>
        <div>
          <Button
            onClick={() => {
              dispatch(deleteAllCharges());
            }}
            className={"MyButton"}
          >
            {props.delete}
          </Button>
        </div>
      </div>
    </div>
  );
}
