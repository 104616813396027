import "./dynamicArrayFoyer.scss";
import { Button } from "../../component/button/button";
import { useDispatch, useSelector } from "react-redux";
import { DropDown } from "../../component/dropDown/dropDown";
import { MyInput } from "../../component/input/myInput";
import {
  deleteAllRevenus,
  deleteRevenus,
  setRevenusParAns,
  setRevenusParAnsConjoint,
  setRevenusParMois,
  setRevenusParMoisConjoint,
  setTypeRevenus,
} from "../../store/storeData";
import { putSpaces, regexRemoveSpaces } from "../../utils/functions";

export function DynamicArrayFoyer(props) {
  const dispatch = useDispatch();

  const addCard = () => {
    dispatch(props.addValueReducer());
  };

  const statutFamillial = useSelector(
    (state) => state.inputMember.statusMarital
  );

  // Construction du tableau

  let table = [];
  for (let i = 0; i < props.fields.length; i++) {
    table.push(
      <div className="RevenusInput" key={i}>
        <div className={"Actif"}>
            <div style={{color: "red"}}>*</div>
            <div className={"drop"}>
            <DropDown>
              <select
                name="Revenus Foyer"
                className="revenus-foyer"
                id="lang"
                value={props.fields[i].typeRevenus}
                onChange={(e) => {
                  dispatch(
                    setTypeRevenus({
                      value: e.target.value,
                      index: i,
                    })
                  );
                }}
              >
                <option value="">Type Revenus</option>
                <option value="activite">Revenus d'activité</option>
                <option value="mobilier">Revenus Mobiliers</option>
                <option value="immobilier">Revenus Immobiliers</option>
              </select>
            </DropDown>
          </div>
            <MyInput
            placeholder="Revenus /an"
            className="TextBox revenus-ans"
            obligatoire={true}
            type="text"
            id="MyInput"
            value={props.fields[i].revenusParAns}
            onChange={(e) => {
              dispatch(
                setRevenusParAns({
                  value: putSpaces(e.target.value, 3),
                  index: i,
                })
              );
              dispatch(
                setRevenusParMois({
                  value:
                    putSpaces(
                      parseInt(
                        parseInt(regexRemoveSpaces(e.target.value)) / 12
                      ),
                      3
                    ) !== "0"
                      ? putSpaces(
                          parseInt(
                            parseInt(regexRemoveSpaces(e.target.value)) / 12
                          ),
                          3
                        )
                      : "",
                  index: i,
                })
              );
            }}
          />
          <MyInput
            placeholder="Revenus /mois"
            className="TextBox revenus-mois"
            obligatoire={true}
            type="text"
            id="MyInput"
            value={props.fields[i].revenusParMois}
            onChange={(e) => {
              dispatch(
                setRevenusParMois({
                  value: putSpaces(e.target.value, 3),
                  index: i,
                })
              );
              dispatch(
                setRevenusParAns({
                  value:
                    putSpaces(
                      parseInt(
                        parseInt(regexRemoveSpaces(e.target.value)) * 12
                      ),
                      3
                    ) !== "0"
                      ? putSpaces(
                          parseInt(
                            parseInt(regexRemoveSpaces(e.target.value)) * 12
                          ),
                          3
                        )
                      : "",
                  index: i,
                })
              );
            }}
          />
          <div className={"Data"}>
            <div className={"Actif"}>
              {statutFamillial === "Marié" || statutFamillial === "Pacsé" ? (
                <>
                  <MyInput
                    placeholder="Revenus/an Conjoint"
                    className="TextBox revenus-ans-conjoint"
                    type="text"
                    id="MyInput"
                    obligatoire={true}
                    value={props.fields[i].revenusParAnsConjoint}
                    onChange={(e) => {
                      dispatch(
                        setRevenusParAnsConjoint({
                          value: putSpaces(e.target.value, 3),
                          index: i,
                        })
                      );
                      dispatch(
                        setRevenusParMoisConjoint({
                          value:
                            putSpaces(
                              parseInt(
                                parseInt(regexRemoveSpaces(e.target.value)) / 12
                              ),
                              3
                            ) !== "0"
                              ? putSpaces(
                                  parseInt(
                                    parseInt(
                                      regexRemoveSpaces(e.target.value)
                                    ) / 12
                                  ),
                                  3
                                )
                              : "",
                          index: i,
                        })
                      );
                    }}
                  />
                  <MyInput
                    placeholder="Revenus/mois Conjoint"
                    className="TextBox revenus-mois-conjoint"
                    type="text"
                    id="MyInput"
                    obligatoire={true}
                    value={props.fields[i].revenusParMoisConjoint}
                    onChange={(e) => {
                      dispatch(
                        setRevenusParMoisConjoint({
                          value: putSpaces(e.target.value, 3),
                          index: i,
                        })
                      );
                      dispatch(
                        setRevenusParAnsConjoint({
                          value:
                            putSpaces(
                              parseInt(
                                parseInt(regexRemoveSpaces(e.target.value)) * 12
                              ),
                              3
                            ) !== "0"
                              ? putSpaces(
                                  parseInt(
                                    parseInt(
                                      regexRemoveSpaces(e.target.value)
                                    ) * 12
                                  ),
                                  3
                                )
                              : "",
                          index: i,
                        })
                      );
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={"Delete"}>
            <div
              className={"DeleteIcon"}
              onClick={() => {
                dispatch(deleteRevenus(i));
              }}
            >
              🗑
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={"Data"}></div>
      <form onSubmit={(e) => {}}>{table}</form>
      <div className={"Manage"}>
        <div className={"AddingButton"}>
          <Button
            onClick={(e) => {
              addCard();
            }}
            className={"MyButton"}
          >
            {props.create}
          </Button>
        </div>
        <div>
          <Button
            onClick={() => {
              dispatch(deleteAllRevenus());
            }}
            className={"MyButton"}
          >
            {props.delete}
          </Button>
        </div>
      </div>
    </div>
  );
}
