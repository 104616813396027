import React from "react";
import "./navBar.scss"
import Logo from "../../picture/GraphiqueCollé-3.png"

export function NavBar() {
    return (<div className={"NavBar"}>
        <div>
            <img src={Logo} alt="Logo de Lexem" className={"LogoLexem"}/>
        </div>
    </div>);
}