import React, { useState } from "react";
import { Members } from "../../component/members/members";
import "./statusMembers.scss";
import { Marie } from "../../component/members/marie";
import { Navigation } from "../../component/navigation/navigation";
import { DropDown } from "../../component/dropDown/dropDown";
import { useDispatch, useSelector } from "react-redux";
import "../../component/input/myInput.scss";
import {
  addCharge,
  addEpargne,
  addHouse,
  addProtection,
  addRevenus,
  setAcquisitionHouse,
  setAdress,
  setAssureurEpargne,
  setAssureurProtection,
  setButRechercheEpargne,
  setCapitalConstitueEpargne,
  setCapitalHouse,
  setConstituerCapital,
  setConstituerPatrimoine,
  setDate,
  setDataLoaded,
  setDateConjoint,
  setDateFonctionExerce,
  setDefiscalationInvestis,
  setDefiscalisationConnaissance,
  setDurationHouse,
  setEmailConjoint,
  setExerceFonctionPolitique,
  setFamilleOuEntourage,
  setFirstName,
  setFirstNameConjoint,
  setFonctionExerce,
  setFonctionExerceFriend,
  setFondsEuroConnaissanc,
  setFondsEuroInvestis,
  setFondsFormuleConnaissance,
  setFondsFormuleInvestis,
  setGender,
  setGenderConjoint,
  setLastName,
  setLastNameConjoint,
  setLienPersonne,
  setMensualiteCharge,
  setMensualiteEpargne,
  setMensualiteHouse,
  setMensualiteProtection,
  setNationaliteUS,
  setNbrChildren,
  setNumeroFiscalUS,
  setOpcActionsConnaissance,
  setOpcActionsInvestis,
  setOpcImmobiliersConnaissance,
  setOpcImmobiliersInvestis,
  setOpcMonetairesConnaissance,
  setOpcMonetairesInvestis,
  setOptimiserFiscalite,
  setOptimiserTransmissionPatrimoine,
  setOrigineDesFondsInvestis,
  setPhoneUser,
  setPhoneUserConjoint,
  setPostalCode,
  setPreparerRetraite,
  setProduitProtection,
  setProfession,
  setProfessionConjoint,
  setProtectionDead,
  setProtegerProche,
  setProtegerSante,
  setQuestion1,
  setQuestion2,
  setQuestion3,
  setQuestion4,
  setQuestion5,
  setResidentFiscalUS,
  setRevenusParAns,
  setRevenusParMois,
  setRevenusParMoisConjoint,
  setSociete,
  setSocieteConjoint,
  setStatusMarital,
  setStatusSalarialConjoint,
  setTown,
  setTypeCharge,
  setTypeEpargne,
  setTypeRevenus,
  setStatutProfessionnel,
  setTypeHouse,
  setLoyerHouse,
  setRevenusParAnsConjoint,
  setAssuranceVie,
  setAssuranceEmprunteur,
  setPrevoyancePro,
  setImmobilierPinel,
  setMutuelle,
  setEmail,
  setMutuelleOptique,
  setMutuelleDentaire,
  setSoinsVille,
  setMedecineAlternative,
} from "../../store/storeData";
import { putSpaces } from "../../utils/functions";
import { useSearchParams } from "react-router-dom";
import Loader from "../../component/loader/Loader";
import Alert from "../../component/alert/alert";

export function StatusMembers() {
  const dispatch = useDispatch();
  const data = useSelector((store) => store.inputMember);
  const dataConjoint = useSelector((store) => store.inputConjoint);
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get("recordId");
  const [errors, setErrors] = useState([]);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  function memberData(client) {
    console.log(client);
    dispatch(setFirstName(client.firstName));
    dispatch(setLastName(client.lastName));
    dispatch(setDate(client.date));
    dispatch(setPhoneUser(putSpaces(client.phone, 2)));
    client.nbrChildren === null
      ? dispatch(setNbrChildren("0"))
      : dispatch(setNbrChildren(client.nbrChildren));
    dispatch(setStatusMarital(client.statusMarital));
    dispatch(setProfession(client.profession));
    dispatch(setSociete(client.societe));
    dispatch(setAdress(client.adress));
    dispatch(setPostalCode(client.postalCode));
    dispatch(setTown(client.ville));
    dispatch(setStatutProfessionnel(client.statutProfessionnel));
    dispatch(setGender(client.gender));
    dispatch(setEmail(client.email));
  }

  function conjointData(conjoint) {
    dispatch(setFirstNameConjoint(conjoint.firstName));
    dispatch(setLastNameConjoint(conjoint.lastName));
    dispatch(setDateConjoint(conjoint.date));
    dispatch(setPhoneUserConjoint(conjoint.phone));
    dispatch(setEmailConjoint(conjoint.email));
    dispatch(setGenderConjoint(conjoint.gender));
    dispatch(setProfessionConjoint(conjoint.profession));
    dispatch(setSocieteConjoint(conjoint.societe));
    dispatch(setStatusSalarialConjoint(conjoint.statusSalarial));
  }

  function houseData(house, i, j) {
    if (j !== 0) {
      dispatch(addHouse());
    }

    dispatch(
      setTypeHouse({
        value: house[i].typeHouse,
        index: j,
      })
    );

    dispatch(
      setLoyerHouse({
        value: putSpaces(parseInt(house[i].loyerPercu), 3),
        index: j,
      })
    );

    dispatch(
      setAcquisitionHouse({
        value: putSpaces(parseInt(house[i].valueAcquisition), 3),
        index: j,
      })
    );
    dispatch(
      setCapitalHouse({
        value: putSpaces(parseInt(house[i].capital), 3),
        index: j,
      })
    );
    dispatch(
      setMensualiteHouse({
        value: putSpaces(parseInt(house[i].mensualite), 3),
        index: j,
      })
    );
    dispatch(
      setDurationHouse({
        value: house[i].duration,
        index: j,
      })
    );
  }

  function protectionData(protection, i, j) {
    if (j !== 0) dispatch(addProtection());

    dispatch(
      setProduitProtection({
        value: protection[i].produitProtection,
        index: j,
      })
    );
    dispatch(
      setMensualiteProtection({
        value: putSpaces(parseInt(protection[i].mensualite), 3),
        index: j,
      })
    );
    dispatch(
      setAssureurProtection({
        value: protection[i].assureur,
        index: j,
      })
    );
  }

  function epargneData(epargne, i, j) {
    if (j !== 0) {
      dispatch(addEpargne());
    }

    dispatch(
      setTypeEpargne({
        value: epargne[i].typeEpargne,
        index: j,
      })
    );
    dispatch(
      setCapitalConstitueEpargne({
        value: putSpaces(parseInt(epargne[i].capitalConstitue), 3),
        index: j,
      })
    );
    dispatch(
      setMensualiteEpargne({
        value: putSpaces(parseInt(epargne[i].mensualite), 3),
        index: j,
      })
    );
    dispatch(
      setAssureurEpargne({
        value: epargne[i].assureur,
        index: j,
      })
    );
    dispatch(
      setButRechercheEpargne({
        value: epargne[i].butRecherche,
        index: j,
      })
    );
  }

  function revenusFoyerData(revenusFoyer, i, j) {
    if (j !== 0) {
      dispatch(addRevenus());
    }
    dispatch(
      setTypeRevenus({
        value: revenusFoyer[i].typeRevenus,
        index: j,
      })
    );
    dispatch(
      setRevenusParAns({
        value: putSpaces(parseInt(revenusFoyer[i].revenusParAns), 3),
        index: j,
      })
    );
    dispatch(
      setRevenusParMois({
        value: putSpaces(parseInt(revenusFoyer[i].revenusParMois), 3),
        index: j,
      })
    );
    dispatch(
      setRevenusParAnsConjoint({
        value: putSpaces(parseInt(revenusFoyer[i].revenusParAnsConjoint), 3),
        index: j,
      })
    );
    dispatch(
      setRevenusParMoisConjoint({
        value: putSpaces(parseInt(revenusFoyer[i].revenusParMoisConjoint), 3),
        index: j,
      })
    );
  }

  function chargesFoyerData(chargesFoyer, i, j) {
    if (j !== 0) dispatch(addCharge());
    dispatch(
      setTypeCharge({
        value: chargesFoyer[i].typeCharges,
        index: j,
      })
    );
    dispatch(
      setMensualiteCharge({
        value: putSpaces(parseInt(chargesFoyer[i].mensualiteCharges), 3),
        index: j,
      })
    );
  }

  function ObjectifPatrimoniauxData(
    objectifPatrimoniaux,
    objectifPatrimoniauxOther
  ) {
    dispatch(setPreparerRetraite(objectifPatrimoniaux.preparerRetraite));
    dispatch(
      setConstituerPatrimoine(objectifPatrimoniaux.constituerPatrimoine)
    );
    dispatch(setOptimiserFiscalite(objectifPatrimoniaux.optimiserFiscalite));
    dispatch(setProtectionDead(objectifPatrimoniaux.protectionDead));
    dispatch(setConstituerCapital(objectifPatrimoniaux.constituerCapital));
    dispatch(
      setOptimiserTransmissionPatrimoine(
        objectifPatrimoniaux.optimiserTransmissionPatrimoine
      )
    );
    dispatch(setProtegerProche(objectifPatrimoniaux.protegerProche));
    dispatch(setProtegerSante(objectifPatrimoniaux.protegerSante));
    dispatch(setNationaliteUS(objectifPatrimoniauxOther.nationaliteUS));
    dispatch(setResidentFiscalUS(objectifPatrimoniauxOther.residentFiscalUS));
    dispatch(setNumeroFiscalUS(objectifPatrimoniauxOther.numeroFiscalUS));
    dispatch(
      setExerceFonctionPolitique(
        objectifPatrimoniauxOther.exerceFonctionPolitique
      )
    );
    dispatch(setFonctionExerce(objectifPatrimoniauxOther.fonctionExerce));
    dispatch(
      setDateFonctionExerce(objectifPatrimoniauxOther.dateFonctionExerce)
    );
    dispatch(
      setFamilleOuEntourage(objectifPatrimoniauxOther.familleOuEntourage)
    );
    dispatch(setLienPersonne(objectifPatrimoniauxOther.lienPersonne));
    dispatch(
      setFonctionExerceFriend(objectifPatrimoniauxOther.fonctionExerceFriend)
    );
    dispatch(
      setOrigineDesFondsInvestis(
        objectifPatrimoniauxOther.origineDesFondsInvestis
      )
    );
  }

  function profileInvestisseurData(profileInvestisseur) {
    dispatch(setQuestion1(profileInvestisseur.question1.value));
    dispatch(setQuestion2(profileInvestisseur.question2.value));
    dispatch(setQuestion3(profileInvestisseur.question3.value));
    dispatch(setQuestion3(profileInvestisseur.question3.value));
    dispatch(setQuestion4(profileInvestisseur.question4.value));
    dispatch(setQuestion5(profileInvestisseur.question5.value));
    dispatch(
      setFondsEuroConnaissanc(
        profileInvestisseur.question3.array.connaissance.fondEuros.value
      )
    );
    dispatch(
      setOpcMonetairesConnaissance(
        profileInvestisseur.question3.array.connaissance.opcActions.value
      )
    );
    dispatch(
      setOpcImmobiliersConnaissance(
        profileInvestisseur.question3.array.connaissance.opcImmobiliers.value
      )
    );
    dispatch(
      setDefiscalisationConnaissance(
        profileInvestisseur.question3.array.connaissance.defiscalisation.value
      )
    );
    dispatch(
      setOpcActionsConnaissance(
        profileInvestisseur.question3.array.connaissance.opcActions.value
      )
    );
    dispatch(
      setFondsFormuleConnaissance(
        profileInvestisseur.question3.array.connaissance.fondsFormule.value
      )
    );
    dispatch(
      setFondsEuroInvestis(
        profileInvestisseur.question3.array.investis.fondEuros.value
      )
    );
    dispatch(
      setOpcMonetairesInvestis(
        profileInvestisseur.question3.array.investis.opcMonetaires.value
      )
    );
    dispatch(
      setOpcActionsInvestis(
        profileInvestisseur.question3.array.investis.opcActions.value
      )
    );
    dispatch(
      setDefiscalationInvestis(
        profileInvestisseur.question3.array.investis.defiscalisation.value
      )
    );
    dispatch(
      setOpcImmobiliersInvestis(
        profileInvestisseur.question3.array.investis.opcImmobiliers.value
      )
    );
    dispatch(
      setFondsFormuleInvestis(
        profileInvestisseur.question3.array.investis.fondsFormule.value
      )
    );
  }

  function produitDispoData(produitDispo) {
    dispatch(setFondsFormuleInvestis(produitDispo.planEpargneRetraite));
    dispatch(setAssuranceVie(produitDispo.assuranceVie));
    dispatch(setAssuranceEmprunteur(produitDispo.assuranceEmprunteur));
    dispatch(setPrevoyancePro(produitDispo.prevoyancePro));
    dispatch(setImmobilierPinel(produitDispo.immobilierPinel));
    dispatch(setMutuelle(produitDispo.mutuelle));
    dispatch(setMutuelleOptique(produitDispo.mutuelleOptique));
    dispatch(setMutuelleDentaire(produitDispo.mutuelleDentaire));
    dispatch(setSoinsVille(produitDispo.soinsVille));
    dispatch(setMedecineAlternative(produitDispo.medecineMutuelle));

  }

  function setDataSelforceToWebApp(result) {
    result = JSON.parse(JSON.stringify(result).replace(/:null/gi, ':""'));
    const client = result.slide1.client;
    const conjoint = result.slide1.conjoint;
    const house = result.slide2.house;
    const epargne = result.slide3.epargne;
    const protection = result.slide3.protection;
    const revenusFoyer = result.slide4.revenusFoyer;
    const chargesFoyer = result.slide5.charges;
    const objectifPatrimoniaux = result.slide6.objectifs;
    const objectifPatrimoniauxOther = result.slide6.other;
    const profileInvestisseur = result.slide8;
    const produitDispo = result.slide7.produits;

    memberData(client);
    if (conjoint.firstName !== "") {
      conjointData(conjoint);
    }
    for (let i = 0, j = 0; i !== house.length; i++) {
      if (
        (house[i].valueAcquisition !== 0 && house[i].valueAcquisition !== "") ||
        (house[i].capital !== 0 && house[i].capital !== "") ||
        (house[i].mensualite !== 0 && house[i].mensualite !== "")
      ) {
        houseData(house, i, j);
        j++;
      }
    }

    for (let i = 0, j = 0; i !== protection.length; i++) {
      if (
        (protection[i].mensualite !== 0 && protection[i].mensualite !== "") ||
        (protection[i].assureur !== "" && protection[i].assureur !== "")
      ) {
        protectionData(protection, i, j);
        j++;
      }
    }

    for (let i = 0, j = 0; i !== epargne.length; i++) {
      if (
        (epargne[i].capitalConstitue !== 0 &&
          epargne[i].capitalConstitue !== "") ||
        (epargne[i].mensualite !== 0 && epargne[i].mensualite !== "")
      ) {
        epargneData(epargne, i, j);
        j++;
      }
    }

    for (let i = 0, j = 0; i !== revenusFoyer.length; i++) {
      if (
        (revenusFoyer[i].revenusParAns !== 0 &&
          revenusFoyer[i].revenusParAns !== "") ||
        (revenusFoyer[i].revenusParMois !== 0 &&
          revenusFoyer[i].revenusParMois !== "") ||
        (revenusFoyer[i].revenusParAnsConjoint !== 0 &&
          revenusFoyer[i].revenusParAnsConjoint !== "") ||
        (revenusFoyer[i].revenusParMoisConjoint !== 0 &&
          revenusFoyer[i].revenusParMoisConjoint !== "")
      ) {
        revenusFoyerData(revenusFoyer, i, j);
        j++;
      }
    }

    for (let i = 0, j = 0; i !== chargesFoyer.length; i++) {
      if (
        chargesFoyer[i].mensualiteCharges !== 0 &&
        chargesFoyer[i].mensualiteCharges !== ""
      ) {
        chargesFoyerData(chargesFoyer, i, j);
        j++;
      }
    }
    ObjectifPatrimoniauxData(objectifPatrimoniaux, objectifPatrimoniauxOther);
    profileInvestisseurData(profileInvestisseur);
    produitDispoData(produitDispo);
  }

  const raw = JSON.stringify({
    recordId: recordId,
  });

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const isDataLoaded = useSelector((state) => state.backend.isDataLoaded);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const statutMartial = useSelector((state) => state.inputMember.statusMarital);

  if (recordId === undefined || recordId === "" || recordId === null) {
    return <p>Erreur, veuillez chargez la WebApp depuis SalesForce</p>;
  }

  if (!isDataLoaded) {
    setLoader(true);
    fetch("https://vamj7s8f82.execute-api.eu-west-3.amazonaws.com/prod/get-data-from-sales-force/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setDataSelforceToWebApp(result);
        setLoader(false);
      })
      .catch((error) => console.log("error", error));
    dispatch(setDataLoaded());
  }

  // function conditionnalDisplay() {

  //   return;
  // }

  function convertData() {
    let obj = {
      gender: data.gender,
      last_name: data.lastName,
      first_name: data.firstName,
      birth_date: data.date,
      nbChildren: parseInt(data.nbrChildren),
      marital_status: data.statusMarital,
      job: data.profession,
      phone: data.phone,
      rue: data.adress,
      ville: data.ville,
      codePostal: parseInt(data.postalCode),
      society: data.societe,
      pro_status: data.statutPro,
      email_perso: data.email,
    };
    if (obj.marital_status === "Marié" || obj.marital_status === "Pacsé")
      obj = {
        ...obj,
        ...{
          c_first_name: dataConjoint.firstName,
          c_last_name: dataConjoint.lastName,
          c_birth_date: dataConjoint.date,
          property_separation: dataConjoint.regimeMatrimonial,
          c_job: dataConjoint.profession,
          c_pro_status: dataConjoint.statusSalarial,
          c_society: dataConjoint.societe,
          email: dataConjoint.email,
          c_phone: dataConjoint.phone,
        },
      };
    for (const [key, value] of Object.entries(obj)) {
      if (value === "") {
        delete obj[key];
      }
    }
    return obj;
  }

  async function SendData() {
    console.log(dataConjoint);
    const bdy = convertData();
    setLoader(true);
    try {
      const res = await fetch(
        `https://vamj7s8f82.execute-api.eu-west-3.amazonaws.com/prod/sf-request/1?recordId=${recordId}`,
        {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(bdy),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!res.ok) {
        const a = await res.text();
        setLoader(false);
        setError(true);
        setErrors(JSON.parse(a).message);

        setTimeout(() => {
          setError(false);
        }, 7000);

        return false;
      } else {
        setLoader(false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <Alert isActive={error} errors={errors} />
      <Loader isActive={loader} />
      <div className={"page-format"}>
        <div>
          <h1>Tout d'abord, faisons connaissance 👋</h1>
        </div>
        <div className="format-sexe">
          <Members>
            <DropDown>
              <div style={{ display: "flex", "flex-direction": "row" }}>
                <label for="first" className={"obligatoire"}>
                  *
                </label>
                <form>
                  <select
                    className={"select"}
                    name="Statut Familial"
                    id="lang"
                    value={statutMartial}
                    onChange={(val) => {
                      dispatch(setStatusMarital(val.target.value));
                    }}
                  >
                    <option value="">Statut familial</option>
                    <option value="Célibataire">Celibataire</option>
                    <option value="Marié">Marié(e)</option>
                    <option value="Pacsé">Pacsé(e)</option>
                    <option value="En concubinage">En concubinage</option>
                    <option value="Divorcé">Divorcé(e)</option>
                    <option value="Veuf.ve">Veuf(ve)</option>
                  </select>
                </form>
              </div>
            </DropDown>
          </Members>
          {statutMartial === "Marié" || statutMartial === "Pacsé" ? (
            <Marie />
          ) : (
            ""
          )}
        </div>
      </div>
      <Navigation
        goto={`/ActifImmobiliers?recordId=${recordId}&securityToken=${searchParams.get(
          "securityToken"
        )}`}
        goback={`/StatusMembers?recordId=${recordId}&securityToken=${searchParams.get(
          "securityToken"
        )}`}
        check={SendData}
      />
    </div>
  );
}
