import React, { useEffect } from "react";
import { Card } from "../../component/card/card";
import "./resumeSituation.scss";
import { Navigation } from "../../component/navigation/navigation";
import { useSelector } from "react-redux";
import { putSpaces, regexRemoveSpaces } from "../../utils/functions";
import { useNavigate, useSearchParams } from "react-router-dom";

export function ResumeSituation() {

  const revenus = useSelector((state) => state.inputRevenus.revenus);
  const charges = useSelector((state) => state.inputChargesFoyer.charges);
  const epargne = useSelector((state) => state.inputEpargne.epargne);
  const immobilier = useSelector((state) => state.inputHouse.house);
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get("recordId");

  let revenusFoyer = 0;
  let chargesFoyer = 0;
  let totalEpargne = 0;
  let totalImmobilier = 0;

  for (let i = 0; i < revenus.length; i++) {
    revenusFoyer += parseInt(regexRemoveSpaces(revenus[i].revenusParMois));
    revenusFoyer += parseInt(
      regexRemoveSpaces(revenus[i].revenusParMoisConjoint)
    );
  }

  for (let i = 0; i < charges.length; i++) {
    chargesFoyer += parseInt(regexRemoveSpaces(charges[i].mensualite));
    chargesFoyer += parseInt(regexRemoveSpaces(charges[i].mensualiteConjoint));
  }

  for (let i = 0; i < epargne.length; i++) {
    totalEpargne += parseInt(regexRemoveSpaces(epargne[i].capitalConstitue));
  }

  for (let i = 0; i < immobilier.length; i++) {
    totalImmobilier += parseInt(
      regexRemoveSpaces(immobilier[i].valueAcquisition)
    );
  }

  const imprevisibles = parseInt((revenusFoyer - chargesFoyer) * 0.25);

  const dataIsLoaded = useSelector((state) => state.backend.isDataLoaded);
  const navigate = useNavigate();

  useEffect(() => {
    if (!dataIsLoaded || recordId === undefined || recordId === "" || searchParams.get("securityToken") === undefined || searchParams.get("securityToken") === "") {
      const interval = setInterval(() => {
        navigate(`/?recordId=${recordId}&securityToken=${searchParams.get("securityToken")}`, { replace: true })
      }, 200);

      return function () {
        clearInterval(interval);
      }
        
    }
  }, [dataIsLoaded, navigate, recordId, searchParams]);

  return (
    <div>
      <div className={"ResumeSituation"}>
        <h1>Résumons votre situation 📜</h1>
        <Card>
          <div className={"Revenus"}>
            <div className={"Revenu"}>
              <p>Revenus du Foyer</p>
              <p className={"Money"}>{putSpaces(revenusFoyer, 3)} €</p>
            </div>
            <div className={"Revenu"}>
              <p>
                <br />
              </p>
              <p className={"Money"}>-</p>
            </div>
            <div className={"Revenu"}>
              <p>Charges du foyer</p>
              <p className={"Money"}>{putSpaces(chargesFoyer, 3)} €</p>
            </div>
            <div className={"Revenu"}>
              <p>
                <br />
              </p>
              <p className={"Money"}>-</p>
            </div>
            <div className={"Revenu"}>
              <p>25% d'imprevisibles</p>
              <p className={"Money"}>{putSpaces(imprevisibles, 3)} €</p>
            </div>
            <div className={"Revenu"}>
              <p>
                <br />
              </p>
              <p className={"Money"}>=</p>
            </div>
            <div className={"Revenu"}>
              <p>Reste à vivre</p>
              <p className={"Money"}>
                {putSpaces(revenusFoyer - chargesFoyer - imprevisibles, 3)} €
              </p>
            </div>
          </div>
        </Card>
        <Card>
          <div className={"Total"}>
            <p id={"Epargne"}>Total Epargne</p>
            <p className={"Money"}>{putSpaces(totalEpargne, 3)} €</p>
          </div>
        </Card>
        <Card>
          <div className={"Total"}>
            <p id={"Immo"}> Total Immobilier</p>
            <p className={"Money"}>{putSpaces(totalImmobilier, 3)} €</p>
          </div>
        </Card>
      </div>
      <Navigation
        goto={`/ObjectifsPatrimoniaux?recordId=${recordId}&securityToken=${searchParams.get("securityToken")}`}
        goback={`/ChargeFoyer?recordId=${recordId}&securityToken=${searchParams.get("securityToken")}`}
        check={() => {}}
      />
    </div>
  );
}
