import React from "react";
import { Card } from "../card/card";
import "./members.scss";
import { MyInput } from "../input/myInput";
import { DropDown } from "../dropDown/dropDown";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAdress,
  setDate,
  setFirstName,
  setGender,
  setLastName,
  setNbrChildren,
  setPhoneUser,
  setPostalCode,
  setProfession,
  setSociete,
  setStatutProfessionnel,
  setTown,
  setEmail,
} from "../../store/storeData";
import { putSpaces } from "../../utils/functions";

import { Modal } from "@mui/material";
import Box from "@mui/material/Box";

export function Members(props) {
  const dispatch = useDispatch();
  //let Phone = useSelector(state => state.store.phone)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [phone] = useState(undefined);
  const adress = useSelector((state) => state.inputMember.adress);
  const zipCode = useSelector((state) => state.inputMember.postalCode);
  const city = useSelector((state) => state.inputMember.ville);
  const gender = useSelector((state) => state.inputMember.gender);

  return (
    <Card>
      <div>
        <div className={"Sexe"}>
          <div className="radio-container-gender">
            <input
              type={"radio"}
              name={"genre"}
              value={"Mme."}
              id={"femme"}
              checked={gender === "Mme."}
              onChange={(event) => {
                dispatch(setGender(event.target.value));
              }}
            />
            <label htmlFor={"femme"} className={"Women"}>
              Femme
            </label>
          </div>
          <div className="radio-container-gender">
            <input
              type={"radio"}
              name={"genre"}
              value={"Mr."}
              id={"homme"}
              checked={gender === "Mr."}
              onChange={(event) => {
                dispatch(setGender(event.target.value));
              }}
            />
            <label htmlFor={"homme"}>Homme</label>
          </div>
        </div>
        <div className={"line"}>
          <MyInput
            obligatoire={true}
            placeholder={"Nom"}
            value={useSelector((state) => state.inputMember.lastName)}
            onChange={(event) => {
              dispatch(setLastName(event.target.value));
            }}
          />
          <MyInput
            placeholder={"Prénom"}
            obligatoire={true}
            value={useSelector((state) => state.inputMember.firstName)}
            onChange={(event) => {
              dispatch(setFirstName(event.target.value));
            }}
          />
        </div>
        <div className={"line"}>
          <MyInput
            obligatoire={true}
            placeholder="dd-mm-yyyy"
            type={"date"}
            value={useSelector((state) => state.inputMember.date)}
            onChange={(event) => {
              dispatch(setDate(event.target.value));
            }}
          />
          <MyInput
            obligatoire={true}
            placeholder={"Numéro de Téléphone"}
            value={useSelector((state) => state.inputMember.phone)}
            onChange={(event) => {
              dispatch(setPhoneUser(putSpaces(event.target.value, 2)));
            }}
            id={"Phone"}
          />
        </div>
        <div className={"line"}>
          <MyInput
            obligatoire={true}
            placeholder={"Nombre d'enfant à charge"}
            type={"number"}
            value={useSelector((state) => state.inputMember.nbrChildren)}
            onChange={(event) => {
              dispatch(setNbrChildren(event.target.value));
            }}
          />
          {props.children}
        </div>
        <div className={"Profession"}>
          <div className={"line"}>
            <MyInput
              placeholder={"Profession"}
              obligatoire={true}
              value={useSelector((state) => state.inputMember.profession)}
              onChange={(event) => {
                dispatch(setProfession(event.target.value));
              }}
            />
            <MyInput
              obligatoire={true}
              placeholder={"Email"}
              value={useSelector((state) => state.inputMember.email)}
              onChange={(event) => {
                dispatch(setEmail(event.target.value));
              }}
            />
            <div className={"ButtonModal"} onClick={handleOpen}>
              {adress === "" ? "Adresse personelle" : adress}
            </div>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={"ModalStyle"}>
                <div className="close-modal-container">
                  <button
                    id={"button-close-modal"}
                    onClick={(e) => setOpen(false)}
                  >
                    X
                  </button>
                </div>

                <div className={"ModalInterface"}>
                  <MyInput
                    id="modal"
                    placeholder={"Numéro et rue"}
                    value={adress}
                    onChange={(event) => {
                      dispatch(setAdress(event.target.value));
                    }}
                  />
                  <MyInput
                    id="modal"
                    placeholder={"Code Postal"}
                    value={zipCode}
                    onChange={(event) => {
                      dispatch(setPostalCode(event.target.value));
                    }}
                  />
                  <MyInput
                    id="modal"
                    placeholder={"Ville"}
                    value={city}
                    onChange={(event) => {
                      dispatch(setTown(event.target.value));
                    }}
                  />
                </div>
              </Box>
            </Modal>
          </div>
          <div className={"line"}>
            <MyInput
              placeholder={"Société"}
              value={useSelector((state) => state.inputMember.societe)}
              onChange={(event) => {
                dispatch(setSociete(event.target.value));
              }}
            />
            <div style={{ color: "red" }}>*</div>
            <DropDown>
              <form>
                <select
                  className={"select"}
                  value={useSelector((state) => state.inputMember.statutPro)}
                  onChange={(event) => {
                    dispatch(setStatutProfessionnel(event.target.value));
                  }}
                >
                  <option value="">Statut professionnel</option>
                  <option value="TNS (SSI, ex RSI)">TNS (SSI, ex RSI)</option>
                  <option value="Salarié(e)">Salarié(e)</option>
                  <option value="Autre">Autre</option>
                  <option value="Etudiant(e) / Apprenti(e)">
                    Etudiant(e) / Apprenti(e)
                  </option>
                  <option value="Demandeur d'emploi">Demandeur d'emploi</option>
                  <option value="Autre">Sans Emploi</option>
                </select>
              </form>
            </DropDown>
          </div>
        </div>
      </div>
    </Card>
  );
}
