import React, { useEffect, useState } from "react";
import { Card } from "../../component/card/card";
import { MyInput } from "../../component/input/myInput";
import "./profileInvestisseur.scss";
import Checkbox from "@mui/material/Checkbox";
import { Navigation } from "../../component/navigation/navigation";
import {
  setDefiscalisationConnaissance,
  setDefiscalationInvestis,
  setFondsEuroConnaissanc,
  setFondsEuroInvestis,
  setFondsFormuleConnaissance,
  setFondsFormuleInvestis,
  setOpcActionsConnaissance,
  setOpcActionsInvestis,
  setOpcImmobiliersConnaissance,
  setOpcImmobiliersInvestis,
  setOpcMonetairesConnaissance,
  setOpcMonetairesInvestis,
  setQuestion1,
  setQuestion1Point,
  setQuestion2,
  setQuestion3,
  setQuestion3Point,
  setQuestion4,
  setQuestion4Point,
  setQuestion5,
  setQuestion5Point,
  setTotauxScoreInfProfileQ3,
  setTotauxScoreSupProfileQ3,
  setScoreConnaissance,
  setScoreInvestis,
  setScoreTotal,
  setDateRdv,
  setResultsPrudent,
  setResultsEquilibre,
  setResultsDynamique,
} from "../../store/storeData";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Alert from "../../component/alert/alert";
import Loader from "../../component/loader/Loader";

const question1Answer = {
  placementA: 0,
  placementB: 2,
  placementC: 3,
};

const question4Answer = {
  vends: 0,
  conserve: 3,
  investis: 6,
};

const question5Answer = {
  moins4ans: 0,
  entre4Et8Ans: 1,
  entre8Et15Ans: 2,
  plus15ans: 3,
};

export function ProfileInvestisseur() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);

  let fondEurosConnaissance = useSelector(
    (state) =>
      state.inputStoreDataProfileInvestisseur.question3.array.connaissance
        .fondEuros
  );
  let fondEurosInvestis = useSelector(
    (state) =>
      state.inputStoreDataProfileInvestisseur.question3.array.investis.fondEuros
  );
  let opcMonetairesConnaissance = useSelector(
    (state) =>
      state.inputStoreDataProfileInvestisseur.question3.array.connaissance
        .opcMonetaires
  );
  let opcMonetairesInvestis = useSelector(
    (state) =>
      state.inputStoreDataProfileInvestisseur.question3.array.investis
        .opcMonetaires
  );
  let opcActionsConnaissance = useSelector(
    (state) =>
      state.inputStoreDataProfileInvestisseur.question3.array.connaissance
        .opcActions
  );
  let opcActionsInvestis = useSelector(
    (state) =>
      state.inputStoreDataProfileInvestisseur.question3.array.investis
        .opcActions
  );
  let opcImmobilierConnaissance = useSelector(
    (state) =>
      state.inputStoreDataProfileInvestisseur.question3.array.connaissance
        .opcImmobiliers
  );
  let opcImmobilierInvestis = useSelector(
    (state) =>
      state.inputStoreDataProfileInvestisseur.question3.array.investis
        .opcImmobiliers
  );
  let fondsFormuleConnaissance = useSelector(
    (state) =>
      state.inputStoreDataProfileInvestisseur.question3.array.connaissance
        .fondsFormule
  );
  let fondsFormuleInvestis = useSelector(
    (state) =>
      state.inputStoreDataProfileInvestisseur.question3.array.investis
        .fondsFormule
  );
  let defiscalisationConnaissance = useSelector(
    (state) =>
      state.inputStoreDataProfileInvestisseur.question3.array.connaissance
        .defiscalisation
  );
  let defiscalisationInvestis = useSelector(
    (state) =>
      state.inputStoreDataProfileInvestisseur.question3.array.investis
        .defiscalisation
  );
  let question1 = useSelector(
    (state) => state.inputStoreDataProfileInvestisseur.question1
  );
  let question2 = useSelector(
    (state) => state.inputStoreDataProfileInvestisseur.question2
  );
  let question3 = useSelector(
    (state) => state.inputStoreDataProfileInvestisseur.question3
  );
  let question4 = useSelector(
    (state) => state.inputStoreDataProfileInvestisseur.question4
  );
  let question5 = useSelector(
    (state) => state.inputStoreDataProfileInvestisseur.question5
  );
  const data = useSelector((store) => store.inputStoreDataProfileInvestisseur);
  let calculScore =
    question1.point +
    (question3.value === "pasConnaissances" ? 0 : question3.point) +
    question4.point +
    question5.point;
  const littleScore = useSelector((store) => store.inputStoreDataProfileInvestisseur.results.prudent);
  const midleScore = useSelector((store) => store.inputStoreDataProfileInvestisseur.results.equilibre);
  const lastScore = useSelector((store) => store.inputStoreDataProfileInvestisseur.results.dynamique);

  let calculConnaissance =
    (fondEurosConnaissance.value === true ? fondEurosConnaissance.point : 0) +
    (opcMonetairesConnaissance.value === true
      ? opcMonetairesConnaissance.point
      : 0) +
    (opcActionsConnaissance.value === true ? opcActionsConnaissance.point : 0) +
    (opcImmobilierConnaissance.value === true
      ? opcImmobilierConnaissance.point
      : 0) +
    (fondsFormuleConnaissance.value === true
      ? fondsFormuleConnaissance.point
      : 0) +
    (defiscalisationConnaissance.value === true
      ? fondsFormuleConnaissance.point
      : 0);

  let calculInvestis =
    (fondEurosInvestis.value === true ? fondEurosInvestis.point : 0) +
    (opcMonetairesInvestis.value === true ? opcMonetairesInvestis.point : 0) +
    (opcActionsInvestis.value === true ? opcActionsInvestis.point : 0) +
    (opcImmobilierInvestis.value === true ? opcImmobilierInvestis.point : 0) +
    (fondsFormuleInvestis.value === true ? fondsFormuleInvestis.point : 0) +
    (defiscalisationInvestis.value === true
      ? defiscalisationInvestis.point
      : 0);

  const setValue = () => {
    for (const [key, value] of Object.entries(question1Answer)) {
      if (question1.value === key) {
        dispatch(setQuestion1Point(value));
        break;
      }
    }
    for (const [key, value] of Object.entries(question4Answer)) {
      if (question4.value === key) {
        dispatch(setQuestion4Point(value));
        break;
      }
    }
    for (const [key, value] of Object.entries(question5Answer)) {
      if (question5.value === key) {
        dispatch(setQuestion5Point(value));
        break;
      }
    }
    return;
  };

  function copyWithoutProperty(obj, prop) {
    const newobj = {};
    for (const [key, val] of Object.entries(obj)) {
      if (!(key in prop)) newobj[key] = val;
    }
    prop.forEach((elem) => {
      newobj[elem] = {};
    });
    newobj[prop] = {};
    return newobj;
  }

  function checkData() {
    var obj = {};

    obj = copyWithoutProperty(data, ["question3"]);
    obj.question3 = copyWithoutProperty(data.question3, ["array"]);
    obj.question3.array = copyWithoutProperty(data.question3.array, [
      "connaissance",
      "investis",
    ]);
    obj.question3.array.connaissance = copyWithoutProperty(
      data.question3.array.connaissance,
      ["score"]
    );
    obj.question3.array.investis = copyWithoutProperty(
      data.question3.array.investis,
      ["score"]
    );
    obj.question3.array.connaissance.score = {
      point: data.question3.array.connaissance.score,
    };
    obj.question3.array.investis.score = {
      point: data.question3.array.investis.score,
    };
    //const tmp = obj.question3.array.connaissance.score;
    // obj.question3.array.connaissance["score"] = tmp;
    // obj.question3.array.connaissance.score = {
    //   point: obj.question3.array.connaissance.score,avasavavasas
    // };
    return obj;
  }

  async function SendData() {
    const body = checkData();
    setLoader(true);
    try {
      const res = await fetch(
        `https://vamj7s8f82.execute-api.eu-west-3.amazonaws.com/prod/sf-request/8?recordId=${recordId}`,
        {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!res.ok) {
        const a = await res.text();
        console.log(a);

        setError(true);
        setErrors(JSON.parse(a).message);
        setLoader(false);

        setTimeout(() => {
          setError(false);
        }, 7000);

        return false;
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  }

  const [q3resultInf, setQ3resultInf] = useState(true);
  const [q3resultSup, setQ3resultSup] = useState(false);

  useEffect(() => {
    setValue();
  });

  let connaissanceorNot = useSelector(
    (state) => state.inputStoreDataProfileInvestisseur.question3.value
  );

  function popTable() {
    if (connaissanceorNot === "connaissances")
      return (
        <Card>
          <table>
            <thead>
              <tr>
                <th>{""}</th>
                <th>
                  {
                    "Cochez les produits dont vous connaissez les principales caractéristiques et les risques associés , y compris les risques de perte en capital"
                  }
                </th>
                <th>
                  {
                    "Cochez les produits sur lesquels vous avez investi au cours des 3 dernières années"
                  }
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{"Fonds en euros"}</td>
                <td>
                  <div>
                    <Checkbox
                      checked={fondEurosConnaissance.value}
                      onChange={(e) => {
                        dispatch(setFondsEuroConnaissanc(e.target.checked));
                      }}
                    ></Checkbox>
                    <label>1</label>
                  </div>
                </td>
                <td>
                  <div>
                    <Checkbox
                      checked={fondEurosInvestis.value}
                      onChange={(e) => {
                        dispatch(setFondsEuroInvestis(e.target.checked));
                      }}
                    ></Checkbox>
                    <label>1</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{"OPC Monétaires"}</td>
                <td>
                  <div>
                    <Checkbox
                      checked={opcMonetairesConnaissance.value}
                      onChange={(e) => {
                        dispatch(
                          setOpcMonetairesConnaissance(e.target.checked)
                        );
                      }}
                    ></Checkbox>{" "}
                    <label>1</label>
                  </div>
                </td>
                <td>
                  <div>
                    <Checkbox
                      checked={opcMonetairesInvestis.value}
                      onChange={(e) => {
                        dispatch(setOpcMonetairesInvestis(e.target.checked));
                      }}
                    ></Checkbox>
                    <label>1</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{"OPC actions, obligations, diversifiés (SICAV, FCP)"}</td>
                <td>
                  <div>
                    <Checkbox
                      checked={opcActionsConnaissance.value}
                      onChange={(e) => {
                        dispatch(setOpcActionsConnaissance(e.target.checked));
                      }}
                    ></Checkbox>
                    <label>1</label>
                  </div>
                </td>
                <td>
                  <div>
                    <Checkbox
                      checked={opcActionsInvestis.value}
                      onChange={(e) => {
                        dispatch(setOpcActionsInvestis(e.target.checked));
                      }}
                    ></Checkbox>
                    <label>2</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{"OPC immobiliers / SCI / SCPI"}</td>
                <td>
                  <div>
                    <Checkbox
                      checked={opcImmobilierConnaissance.value}
                      onChange={(e) => {
                        dispatch(
                          setOpcImmobiliersConnaissance(e.target.checked)
                        );
                      }}
                    ></Checkbox>{" "}
                    <label>1</label>
                  </div>
                </td>
                <td>
                  <div>
                    <Checkbox
                      checked={opcImmobilierInvestis.value}
                      onChange={(e) => {
                        dispatch(setOpcImmobiliersInvestis(e.target.checked));
                      }}
                    ></Checkbox>
                    <label>2</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{"Défiscalisation: FCPI / FIP / FCPR /SOFICA"}</td>
                <td>
                  <div>
                    <Checkbox
                      checked={defiscalisationConnaissance.value}
                      onChange={(e) => {
                        dispatch(
                          setDefiscalisationConnaissance(e.target.checked)
                        );
                      }}
                    ></Checkbox>{" "}
                    <label>1</label>
                  </div>
                </td>
                <td>
                  <div>
                    <Checkbox
                      checked={defiscalisationInvestis.value}
                      onChange={(e) => {
                        dispatch(setDefiscalationInvestis(e.target.checked));
                      }}
                    ></Checkbox>
                    <label>2</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{"Fonds à formule / produits structurés / FIA"}</td>
                <td>
                  <div>
                    <Checkbox
                      checked={fondsFormuleConnaissance.value}
                      onChange={(e) => {
                        dispatch(setFondsFormuleConnaissance(e.target.checked));
                      }}
                    ></Checkbox>{" "}
                    <label>1</label>
                  </div>
                </td>
                <td>
                  <div>
                    <Checkbox
                      checked={fondsFormuleInvestis.value}
                      onChange={(e) => {
                        dispatch(setFondsFormuleInvestis(e.target.checked));
                      }}
                    ></Checkbox>
                    <label>4</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{"SCORE"}</td>
                <td>
                  <p> {calculConnaissance}/ 6</p>
                </td>
                <td>
                  <p> {calculInvestis}/ 12 </p>
                </td>
              </tr>
              <tr>
                <td>{"SCORE TOTAL"}</td>
                <td>
                  <div>
                    <label>{calculConnaissance + calculInvestis}/18</label>
                  </div>
                </td>
                <td>
                  <div>
                    <label>{calculConnaissance + calculInvestis}/18</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{"SCORE"}</td>
                <td>
                  <div>
                    <Checkbox
                      checked={q3resultInf}
                      onChange={(e) => {
                        dispatch(setQuestion3Point(2));
                      }}
                    ></Checkbox>{" "}
                    <label>Inférieur à 10 pts : 2 pts</label>
                  </div>
                </td>
                <td>
                  <div>
                    <Checkbox
                      checked={q3resultSup}
                      onChange={(e) => {
                        dispatch(setQuestion3Point(4));
                      }}
                    ></Checkbox>
                    <label>10 pts et plus : 4 pts</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
      );
  }

  useEffect(() => {
    console.log(calculScore);
    dispatch(setResultsPrudent(calculScore <= 5));
    dispatch(setResultsEquilibre(calculScore >= 6 && calculScore <= 12));
    dispatch(setResultsDynamique(calculScore >= 13));
  }, [calculScore, dispatch]);

  useEffect(() => {
    dispatch(setScoreConnaissance(calculConnaissance));
    dispatch(setScoreInvestis(calculInvestis));
    dispatch(setScoreTotal(calculConnaissance + calculInvestis));
    if (calculConnaissance + calculInvestis < 10) {
      setQ3resultInf(true);
      setQ3resultSup(false);
      dispatch(setTotauxScoreInfProfileQ3(true));
      dispatch(setTotauxScoreSupProfileQ3(false));
      dispatch(setQuestion3Point(2));
    }
    if (calculConnaissance + calculInvestis >= 10) {
      setQ3resultInf(false);
      setQ3resultSup(true);
      dispatch(setTotauxScoreInfProfileQ3(false));
      dispatch(setTotauxScoreSupProfileQ3(true));
      dispatch(setQuestion3Point(4));
    }
  }, [dispatch, calculConnaissance, calculInvestis]);
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get("recordId");

  const dataIsLoaded = useSelector((state) => state.backend.isDataLoaded);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !dataIsLoaded ||
      recordId === undefined ||
      recordId === "" ||
      searchParams.get("securityToken") === undefined ||
      searchParams.get("securityToken") === ""
    ) {
      const interval = setInterval(() => {
        navigate(
          `/?recordId=${recordId}&securityToken=${searchParams.get(
            "securityToken"
          )}`,
          { replace: true }
        );
      }, 200);

      return function () {
        clearInterval(interval);
      };
    }
  }, [dataIsLoaded, navigate, recordId, searchParams]);

  const date_rdv = useSelector(
    (state) => state.inputStoreDataProfileInvestisseur.date_rdv
  );

  return (
    <div className="profil-investisseur">
      <Loader isActive={loader} />
      <Alert isActive={error} errors={errors} />
      <div className={"page-format"}>
        <h1>Scoring profil investisseur 🕵️‍</h1>
        <div className={"PageFormatUnCentered"}>
          <Card>
            <div className="questions">
              <h3>
                1) En matière de placements financiers, les supports
                susceptibles de générer de la performance peuvent également
                connaître des baisses, entraînant des risque de perte en
                capital. Parmi les orientations suivantes, laquelle vous
                correspond le mieux pour cet investissement ?
              </h3>
              <div className={"answer-choice"}>
                <div>
                  <input
                    type={"radio"}
                    name={"question1"}
                    value={"placementA"}
                    id={"placementA"}
                    checked={question1.value === "placementA"}
                    onChange={(e) => {
                      dispatch(setQuestion1(e.target.value));
                      dispatch(setQuestion1Point(0));
                    }}
                  />
                  <label htmlFor={"placementA"}>
                    <b>Placement A:</b> risque faible et un objectif de
                    performance faible, hypothèse non contractuelle et purement
                    indicative de rendement sur une année de +/-5%
                  </label>
                </div>
                <div>
                  <input
                    type={"radio"}
                    name={"question1"}
                    value={"placementB"}
                    id={"placementB"}
                    checked={question1.value === "placementB"}
                    onChange={(e) => {
                      dispatch(setQuestion1(e.target.value));
                      dispatch(setQuestion1Point(2));
                    }}
                  />
                  <label htmlFor={"placementB"}>
                    <b>Placement B:</b> risque modéré et un objectif de
                    performance modéré, hypothèse non contractuelle et purement
                    indicative de rendement sur une année de +/-10%
                  </label>
                </div>
                <div>
                  <input
                    type={"radio"}
                    name={"question1"}
                    value={"placementC"}
                    id={"placementC"}
                    checked={question1.value === "placementC"}
                    onChange={(e) => {
                      dispatch(setQuestion1(e.target.value));
                      dispatch(setQuestion1Point(3));
                    }}
                  />
                  <label htmlFor={"placementC"}>
                    <b>Placement C:</b> risque élevé et un objectif de
                    performance élevé, hypothèse non contractuelle et purement
                    indicative de rendement sur une année de +/-20%
                  </label>
                </div>
              </div>
            </div>
          </Card>
          <Card>
            <div className="questions">
              <h3>
                2) Pour la gestion de votre patrimoine financier vous avez
                l’habitude de faire appel à:
              </h3>
              <div className={"answer-choice"}>
                <div>
                  <input
                    type={"radio"}
                    name={"question2"}
                    value={"gestionLibre"}
                    id={"Gestion_libre"}
                    checked={question2.value === "gestionLibre"}
                    onChange={(e) => {
                      dispatch(setQuestion2(e.target.value));
                    }}
                  />
                  <label htmlFor={"Gestion_libre"}>
                    <b>Gestion libre:</b> je choisis, seul ou avec l’aide d’un
                    conseiller, les supports financiers sur lesquels j’investis.
                  </label>
                </div>
                <div>
                  <input
                    type={"radio"}
                    name={"question2"}
                    value={"gestionSousMandat"}
                    checked={question2.value === "gestionSousMandat"}
                    id={"Gestion_sous_mandat"}
                    onChange={(e) => {
                      dispatch(setQuestion2(e.target.value));
                    }}
                  />
                  <label htmlFor={"Gestion_sous_mandat"}>
                    <b> Gestion sous mandat:</b> je délègue à un mandataire
                    (compagnie d’assurance, banque...) le choix des supports
                    financiers sur lesquels mon épargne est investie.
                    <br /> Le mandataire détermine également pour mon compte la
                    répartition entre les supports et les arbitrages.
                  </label>
                </div>
                <div>
                  <input
                    type={"radio"}
                    name={"question2"}
                    value={"gestionHorizon"}
                    checked={question2.value === "gestionHorizon"}
                    id={"Gestion_à_horizon"}
                    onChange={(e) => {
                      dispatch(setQuestion2(e.target.value));
                    }}
                  />
                  <label htmlFor={"Gestion_à_horizon"}>
                    <b>Gestion à horizon:</b> la répartition de mon épargne
                    entre les supports financiers est définie à l’avance. Mon
                    épargne est automatiquement et progressivement sécurisée à
                    l’approche du terme de mon investissement.
                  </label>
                </div>
              </div>
            </div>
          </Card>
          <Card>
            <div className="questions">
              <h3>
                3) Quel est votre niveau d’expérience des marchés financiers et
                de leur risque de fluctuation à la hausse comme à la baisse
                ainsi que de perte en capital ?
              </h3>
              <div className={"answer-choice"}>
                <div>
                  <input
                    type={"radio"}
                    name={"question3"}
                    value={"pasConnaissances"}
                    id={"No"}
                    checked={question3.value === "pasConnaissances"}
                    onChange={(e) => {
                      dispatch(setQuestion3(e.target.value));
                      dispatch(setQuestion3Point(0));
                    }}
                  />
                  <label htmlFor={"No"}>
                    Je n’ai pas de connaissance et ne réalise pas d’opérations
                    sur des placements ou supports financiers
                  </label>
                </div>
                <div>
                  <input
                    type={"radio"}
                    name={"question3"}
                    value={"connaissances"}
                    checked={question3.value === "connaissances"}
                    id={"Yes"}
                    onChange={(e) => {
                      dispatch(setQuestion3(e.target.value));
                    }}
                  />
                  <label htmlFor={"Yes"}>
                    J’ai des connaissances et/ou de l’expérience (remplir le
                    tableau):
                  </label>
                </div>
              </div>
              {popTable()}
            </div>
          </Card>
          <Card id="FourthQuestion">
            <div className="questions">
              <h3>
                4) Vous possédez un placement dont la valeur a baissée de 20%
                sur une période d’un an, que faites-vous ?
              </h3>
              <div className={"answer-choice"}>
                <div>
                  <input
                    type={"radio"}
                    name={"question4"}
                    value={"vends"}
                    id={"vends"}
                    checked={question4.value === "vends"}
                    onChange={(e) => {
                      dispatch(setQuestion4(e.target.value));
                      dispatch(setQuestion4Point(0));
                    }}
                  />
                  <label htmlFor={"Sell"}>
                    Je vends mon placement, même si cela entraîne une perte
                    immédiate. Ce type de placement de me conviens pas.
                  </label>
                </div>
                <div>
                  <input
                    type={"radio"}
                    name={"question4"}
                    value={"conserve"}
                    id={"conserve"}
                    checked={question4.value === "conserve"}
                    onChange={(e) => {
                      dispatch(setQuestion4(e.target.value));
                      dispatch(setQuestion4Point(3));
                    }}
                  />
                  <label htmlFor={"NoConserve"}>
                    Je conserve mon placement, car il faut s’attendre à des
                    fluctuations du marché. C’est la croissance à moyen/long
                    terme de ce placement qui m’intéresse, et les fluctuations à
                    court terme ne me préoccupent pas.
                  </label>
                </div>
                <div>
                  <input
                    type={"radio"}
                    name={"question4"}
                    value={"investis"}
                    id={"Investis"}
                    checked={question4.value === "investis"}
                    onChange={(e) => {
                      dispatch(setQuestion4(e.target.value));
                      dispatch(setQuestion4Point(6));
                    }}
                  />
                  <label htmlFor={"Investis"}>
                    J’investis des sommes supplémentaires dans ce placement. Ce
                    serait une occasion idéale d’acquérir davantage de parts ou
                    d’actions à un meilleur prix et, par conséquent, d’améliorer
                    le rendement à long terme de mon portefeuille.
                  </label>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Card>
            <div className="questions">
              <h3>5) Quel est votre horizon de placement ?</h3>
              <div className={"answer-choice"}>
                <div>
                  <input
                    type={"radio"}
                    name={"question5"}
                    value={"lessThan4Years"}
                    id={"lessThan4Years"}
                    checked={question5.value === "lessThan4Years"}
                    onChange={(e) => {
                      dispatch(setQuestion5(e.target.value));
                      dispatch(setQuestion5Point(0));
                    }}
                  />
                  <label htmlFor={"lessThan4Years"}>Moins de 4 ans</label>
                </div>
                <div>
                  <input
                    type={"radio"}
                    name={"question5"}
                    value={"between4And8Years"}
                    id={"between4And8Years"}
                    checked={question5.value === "between4And8Years"}
                    onChange={(e) => {
                      dispatch(setQuestion5(e.target.value));
                      dispatch(setQuestion5Point(1));
                    }}
                  />
                  <label htmlFor={"between4And8Years"}>Entre 4 et 8 ans</label>
                </div>
                <div>
                  <input
                    type={"radio"}
                    name={"question5"}
                    value={"between8And15Years"}
                    id={"between8And15Years"}
                    checked={question5.value === "between8And15Years"}
                    onChange={(e) => {
                      dispatch(setQuestion5(e.target.value));
                      dispatch(setQuestion5Point(2));
                    }}
                  />
                  <label htmlFor={"between8And15Years"}>
                    Entre 8 et 15 ans
                  </label>
                </div>
                <div>
                  <input
                    type={"radio"}
                    name={"question5"}
                    value={"moreThan15Years"}
                    id={"moreThan15Years"}
                    checked={question5.value === "moreThan15Years"}
                    onChange={(e) => {
                      dispatch(setQuestion5(e.target.value));
                      dispatch(setQuestion5Point(3));
                    }}
                  />
                  <label htmlFor={"moreThan15Years"}>Plus de 15 ans</label>
                </div>
              </div>
            </div>
          </Card>
          <Card>
            <div className={"rdvDate"}>
              <h3 style={{ marginLeft: "10px", marginRight: "10px" }}>
                Veuillez renseigner la date du rendez-vous:
              </h3>
              <MyInput
                placeholder="dd-mm-yyyy"
                className={"rdv-date"}
                value={date_rdv}
                type={"date"}
                obligatoire={true}
                onChange={(val) => dispatch(setDateRdv(val.target.value))}
                // value={useSelector((state) => state.inputMember.date)}
              />
            </div>
          </Card>
        </div>
        <Card>
          <table>
            <thead>
              <tr>
                <th>{""}</th>
                <th>{"0 à 5 pts"}</th>
                <th>{"6 à 12 pts"}</th>
                <th>{"13 pts et plus"}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{"Score Final"}</td>
                <td>
                  <div>
                    <Checkbox checked={littleScore}></Checkbox>{" "}
                    <label>Prudent</label>
                  </div>
                </td>
                <td>
                  <div>
                    <Checkbox checked={midleScore}></Checkbox>
                    <label>Equilibré</label>
                  </div>
                </td>
                <td>
                  <div>
                    <Checkbox checked={lastScore}></Checkbox>{" "}
                    <label>Dynamique</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
      </div>
      {
        <Navigation
          goto={`/Remerciement?recordId=${recordId}&securityToken=${searchParams.get(
            "securityToken"
          )}`}
          goback={`/Produitsdispo?recordId=${recordId}&securityToken=${searchParams.get(
            "securityToken"
          )}`}
          check={SendData}
        />
      }
    </div>
  );
}
