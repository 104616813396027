import "./dynamicArrayEpargne.scss";
import { Button } from "../../component/button/button";
import { useDispatch, useSelector} from "react-redux";
import { DropDown } from "../../component/dropDown/dropDown";
import { MyInput } from "../../component/input/myInput";
import {
  deleteAllEpargne,
  deleteEpargne,
  setAssureurEpargne,
  setButRechercheEpargne,
  setCapitalConstitueEpargne,
  setMensualiteEpargne,
  setTypeEpargne,
} from "../../store/storeData";
import { putSpaces } from "../../utils/functions";

export function DynamicArrayEpargne(props) {
  const dispatch = useDispatch();

  const addCard = () => {
    dispatch(props.addValueReducer());
  };

  // Construction du tableau
  let table = [];
  for (let i = 0; i < props.fields.length; i++) {
    table.push(
      <div className={"Actif"} key={i}>
        <div className={"drop"}>
          <DropDown id={"drop-produit-epargne"}>
            <select
              name="Epargne"
              id="ep"
              className="DropDownEpargne"
              value={props.fields[i].typeEpargne}
              onChange={(e) => {
                dispatch(
                  setTypeEpargne({
                    value: e.target.value,
                    index: i,
                  })
                );
              }}
            >
              <option value="Produit d'épargne">Produit d'épargne</option>
              <option value="LJ">Livret Jeune</option>
              <option value="LEP">LEP</option>
              <option value="LA">Livret A</option>
              <option value="CEL">CEL</option>
              <option value="LDD">LDD</option>
              <option value="PEL">PEL</option>
              <option value="AV">Assurance vie</option>
              <option value="PER">PER</option>
              <option value="Madelin">Madelin</option>
              <option value="PERP">PERP</option>
              <option value="CompteTitrePEA">Compte Titre ou PEA</option>
            </select>
          </DropDown>
        </div>
        <div className="DropDownEpargne">
          <MyInput
            placeholder="Capital constitue"
            value={props.fields[i].capitalConstitue}
            className="TextBox"
            type="text"
            id="MyInput"
            onChange={(e) => {
              dispatch(
                setCapitalConstitueEpargne({
                  value: putSpaces(e.target.value, 3),
                  index: i,
                })
              );
            }}
          />
          <MyInput
            value={props.fields[i].mensualite}
            placeholder="Mensualite"
            className="TextBox"
            type="text"
            id="MyInput"
            onChange={(e) => {
              dispatch(
                setMensualiteEpargne({
                  value: putSpaces(e.target.value, 3),
                  index: i,
                })
              );
            }}
          />
        </div>
        <div className={"drop"}>
          <DropDown>
            <select
              name="Assureur"
              id="lang"
              value={props.fields[i].assureur}
              onChange={(e) => {
                dispatch(
                  setAssureurEpargne({
                    value: e.target.value,
                    index: i,
                  })
                );
              }}
            >
              <option value="">Assureur / Banque</option>
              <option value="AFI-ESCA">AFI-ESCA</option>
              <option value="AG2R (La Mondiale)">AG2R (La Mondiale)e</option>
              <option value="AGEAS">AGEAS</option>
              <option value="AGIPI">AGIPI</option>
              <option value="Allianz">Allianz</option>
              <option value="Alptis">Alptis</option>
              <option value="APICIL">APICIL</option>
              <option value="Aviva">Aviva</option>
              <option value="Axa">AXA</option>
              <option value="Banque Postale">Banque Postale</option>
              <option value="BNP">BNP</option>
              <option value="Caisse d'Epargne">Caisse Epargne</option>
              <option value="Cardif">Cardif</option>
              <option value="CNP">CNP</option>
              <option value="Covéa">Covéa</option>
              <option value="Crédit Agricole">Crédit Agricole</option>
              <option value="Crédit Mutuel">Crédit Mutuel</option>
              <option value="FWU">FWU</option>
              <option value="Gan">GAN</option>
              <option value="Generali">Générali</option>
              <option value="La Médicale">La Médicale</option>
              <option value="Groupama">Groupama</option>
              <option value="LCL">LCL</option>
              <option value="MACIF">MACIF</option>
              <option value="MACSF">MACSF</option>
              <option value="MATMUT">MATMUT</option>
              <option value="Médicis">Médicis</option>
              <option value="MMA">MAA</option>
              <option value="Néant">Néant</option>
              <option value="Suravenir">Suravenir</option>
              <option value="SwissLife">SwissLife</option>
              <option value="Société Générale">Société Générale</option>
              <option value="Autres">Autres</option>
            </select>
          </DropDown>
        </div>
        <div className={"drop"}>
          <DropDown>
            <select
              name="But recherché"
              value={props.fields[i].butRecherche}
              id="lang"
              onChange={(e) => {
                dispatch(
                  setButRechercheEpargne({
                    value: e.target.value,
                    index: i,
                  })
                );
              }}
            >
              <option value="But recherché">But recherché</option>
              <option value="Épargne de sécurité">Epargne de sécurité</option>
              <option value="Achat immobilier">Achat Immobilier</option>
              <option value="Retraite">Retraite</option>
              <option value="Placement">Placement</option>
            </select>
          </DropDown>
        </div>
        <div className={"Delete"}>
          <div
            className={"DeleteIcon"}
            onClick={() => {
              dispatch(deleteEpargne(i));
            }}
          >
            🗑
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={"Data"}></div>
      <form onSubmit={(e) => {}}>{table}</form>
      <div className={"Manage"}>
        <div className={"AddingButton"}>
          <Button
            onClick={(e) => {
              addCard();
            }}
            className={"MyButton"}
          >
            {props.create}
          </Button>
        </div>
        <div className="DeleteAllEpargne">
          <Button
            onClick={() => {
              dispatch(deleteAllEpargne());
            }}
            className={"MyButton"}
          >
            {props.delete}
          </Button>
        </div>
      </div>
    </div>
  );
}
