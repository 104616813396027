import React, { useEffect, useState } from "react";
import { Card } from "../../component/card/card";
import "./produitsdispo.scss";
import { MyInput } from "../../component/input/myInput";
import { DropDown } from "../../component/dropDown/dropDown";
import { Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setAgeDepartRetraite,
  setAssuranceEmprunteur,
  setAssuranceVie,
  setCapitalRestantAssuranceEmprunteur,
  setDureeAssuranceEmprunteur,
  setFranchisePrevoyancePro,
  setImmobilierPinel,
  setMedecineAlternative,
  setMensualiteAssuranceEmprunteur,
  setMensualiteAssuranceVie,
  setMensualiteEpargneRetraite,
  setMutuelle,
  setMutuelleDentaire,
  setMutuelleOptique,
  setPlanEpargneRetraite,
  setPrevoyancePro,
  setProduitStructure,
  setProfilInvestissement,
  setSoinsVille,
  setTauxInvaliditePro,
  setVersementLibreAssuranceVie,
} from "../../store/storeData";
import { Navigation } from "../../component/navigation/navigation";
import {
  ecoImpotsPer,
  getCapitalPer,
  nbrPart,
  plafondDefisc,
  prixDuBienAndMensualitePinel,
  putSpaces,
  regexRemoveSpaces,
  tmi,
} from "../../utils/functions";
import {
  ACTUAL_YEAR,
  DUREE_EMPRUNT_PINEL,
  TAEG_PINEL,
  TAUX_INTERET_PER,
} from "../../utils/const";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../component/loader/Loader";
import Alert from "../../component/alert/alert";

export function Produitsdispo() {
  const data = useSelector((state) => state.inputStoreDataProduitsDispo);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);

  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const planEpargneIsChecked = useSelector(
    (state) => state.inputStoreDataProduitsDispo.planEpargneRetraite
  );
  const planAssuranceVie = useSelector(
    (state) => state.inputStoreDataProduitsDispo.assuranceVie
  );
  const planAssuranceEmprunteur = useSelector(
    (state) => state.inputStoreDataProduitsDispo.assuranceEmprunteur
  );
  const planPrevoyancePro = useSelector(
    (state) => state.inputStoreDataProduitsDispo.prevoyancePro
  );
  const planImmobilierPinel = useSelector(
    (state) => state.inputStoreDataProduitsDispo.immobilierPinel
  );
  const planMutuelle = useSelector(
    (state) => state.inputStoreDataProduitsDispo.mutuelle
  );
  const revenus = useSelector((state) => state.inputRevenus.revenus);
  const charges = useSelector((state) => state.inputChargesFoyer.charges);
  const franchisePrev = useSelector(
    (state) => state.inputStoreDataProduitsDispo.franchisePrevoyancePro
  );
  const tauxInvaliditePro = useSelector(
    (state) => state.inputStoreDataProduitsDispo.tauxInvaliditePro
  );
  const ageDepartEnRetraite = useSelector(
    (state) => state.inputStoreDataProduitsDispo.ageDepartEnRetraite
  );
  const statutProfessionnel = useSelector(
    (state) => state.inputMember.statutPro
  );
  const versementPer = useSelector(
    (state) => state.inputStoreDataProduitsDispo.mensualiteEpargneRetraite
  );
  const birthdate = useSelector((state) => state.inputMember.date);
  const yearBirth = parseInt(
    birthdate[0] + birthdate[1] + birthdate[2] + birthdate[3]
  );
  const age = ACTUAL_YEAR - yearBirth;
  const nbrKids = useSelector((state) => state.inputMember.nbrChildren);
  const situationFamiliale = useSelector(
    (state) => state.inputMember.statusMarital
  );
  const optiqueMutuelle = useSelector(
    (state) => state.inputStoreDataProduitsDispo.mutuelleOptique
  );
  const dentaireMutuelle = useSelector(
    (state) => state.inputStoreDataProduitsDispo.mutuelleDentaire
  );
  const soinsVilleMutuelle = useSelector(
    (state) => state.inputStoreDataProduitsDispo.soinsVille
  );
  const medecinesAlternativesMutuelle = useSelector(
    (state) => state.inputStoreDataProduitsDispo.medecineAlternative
  );

  const mensualiteAssuranceVie = useSelector(
    (state) => state.inputStoreDataProduitsDispo.mensualiteAssuranceVie
  );
  const versementLibreAssuranceVie = useSelector(
    (state) => state.inputStoreDataProduitsDispo.versementLibreAssuranceVie
  );
  const profilInvestisseurAssuranceVie = useSelector(
    (state) => state.inputStoreDataProduitsDispo.versementLibreAssuranceVie
  );
  const produitStructure = useSelector(
    (state) => state.inputStoreDataProduitsDispo.produitStructureAssuranceVie
  );
  const mensualiteAssuranceEmprunteur = useSelector(
    (state) => state.inputStoreDataProduitsDispo.mensualiteAssuranceEmprunteur
  );
  const capitalRestantDuAssuranceEmprunteur = useSelector(
    (state) =>
      state.inputStoreDataProduitsDispo.capitalRestantAssuranceEmprunteur
  );
  const dureeAssuranceEmprunteur = useSelector(
    (state) => state.inputStoreDataProduitsDispo.dureeAssuranceEmprunteur
  );
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get("recordId");

  let totalRevenus = 0;
  let totalCharges = 0;
  let revenusActivite = 0;

  for (let i = 0; i < revenus.length; i++) {
    totalRevenus += parseInt(regexRemoveSpaces(revenus[i].revenusParMois));
    totalRevenus += parseInt(
      regexRemoveSpaces(revenus[i].revenusParMoisConjoint)
    );

    if (revenus[i].typeRevenus === "activite") {
      revenusActivite =
        putSpaces(parseInt(regexRemoveSpaces(revenus[i].revenusParMois)) + parseInt(regexRemoveSpaces(revenus[i].revenusParMoisConjoint)), 3);
    }
  }

  for (let i = 0; i < charges.length; i++) {
    totalCharges += parseInt(regexRemoveSpaces(charges[i].mensualite));
    totalCharges += parseInt(regexRemoveSpaces(charges[i].mensualiteConjoint));
  }

  // PINEL

  let [pinelPrixDuBien, pinelMensualite] = prixDuBienAndMensualitePinel(
    totalRevenus,
    totalCharges,
    DUREE_EMPRUNT_PINEL,
    TAEG_PINEL
  );
  pinelPrixDuBien = pinelPrixDuBien < 0 ? 0 : pinelPrixDuBien;
  pinelMensualite = pinelMensualite < 0 ? 0 : pinelMensualite;

  let reductionImpotsPinel =
    (pinelPrixDuBien * 2) / 100 > 6000 ? 6000 : (pinelPrixDuBien * 2) / 100;

  // PER
  const myNbrPart = nbrPart(situationFamiliale, parseInt(nbrKids));
  const myTmi = tmi(parseInt(regexRemoveSpaces(revenusActivite)), myNbrPart);
  
  const plafondDefiscalisation = plafondDefisc(
    statutProfessionnel,
    parseInt(regexRemoveSpaces(revenusActivite))
  );
  useEffect(() => {
    dispatch(setMensualiteEpargneRetraite(putSpaces(plafondDefiscalisation, 3)));
  }, [plafondDefiscalisation, dispatch]);

  const myEconomieImpots = ecoImpotsPer(
    statutProfessionnel,
    myTmi,
    parseInt(regexRemoveSpaces(revenusActivite)),
    parseInt(regexRemoveSpaces(versementPer))
  );

  const myEconomieImpotsTerme = myEconomieImpots * (ageDepartEnRetraite - age);

  const capitalTerme = getCapitalPer(
    regexRemoveSpaces(versementPer),
    0,
    age,
    TAUX_INTERET_PER,
    parseInt(ageDepartEnRetraite)
  ).capitalTerme;

  // Reste à vivre :
  const imprevisibles = parseInt((totalRevenus - totalCharges) * 0.25);
  let resteAVivre = totalRevenus - totalCharges - imprevisibles;
  if (planEpargneIsChecked) {
    resteAVivre -= parseInt(regexRemoveSpaces(versementPer));
  }

  if (planAssuranceVie) {
    resteAVivre -= parseInt(regexRemoveSpaces(mensualiteAssuranceVie));
  }

  if (planAssuranceEmprunteur) {
    resteAVivre -= parseInt(regexRemoveSpaces(mensualiteAssuranceEmprunteur));
  }

  if (planImmobilierPinel) {
    resteAVivre -= parseInt(pinelMensualite);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function ouiToBool(str) {
    if (str === "Oui") return true;
    return false;
  }

  function check() {
    let obj = {};
    if (planEpargneIsChecked) {
      obj = {
        ...obj,
        per: {
          mensualite: parseInt(
            regexRemoveSpaces(data.mensualiteEpargneRetraite)
          ),
          eco_impots: myEconomieImpotsTerme,
          capital_terme: capitalTerme,
          selected: planEpargneIsChecked,
        },
      };
    }
    if (planAssuranceVie) {
      obj = {
        ...obj,
        avie: {
          mensualite: parseInt(regexRemoveSpaces(data.mensualiteAssuranceVie)),
          versement_libre: parseInt(
            regexRemoveSpaces(data.versementLibreAssuranceVie)
          ),
          profil_invest: data.profilInvestissement,
          produit_struct: ouiToBool(data.produitStructureAssuranceVie),
          selected: planAssuranceVie,
        },
      };
    }
    if (planAssuranceEmprunteur) {
      obj = {
        ...obj,
        aempr: {
          mensualite: parseInt(
            regexRemoveSpaces(data.mensualiteAssuranceEmprunteur)
          ),
          capital_restant_du: parseInt(
            regexRemoveSpaces(data.capitalRestantAssuranceEmprunteur)
          ),
          duree: parseInt(regexRemoveSpaces(data.dureeAssuranceEmprunteur)),
          selected: planAssuranceEmprunteur,
        },
      };
    }
    if (planPrevoyancePro) {
      obj = {
        ...obj,
        prev: {
          revenus: totalRevenus,
          charges: totalCharges,
          franchise: data.franchisePrevoyancePro,
          taux_invalidite: data.tauxInvaliditePro,
          selected: planPrevoyancePro,
        },
      };
    }
    if (planImmobilierPinel) {
      obj = {
        ...obj,
        pinel: {
          mensualite: pinelMensualite,
          valeur_bien: pinelPrixDuBien,
          eco_impots: reductionImpotsPinel,
          selected: planImmobilierPinel,
        },
      };
    }
    if (planMutuelle) {
      obj = {
        ...obj,
        mut: {
          optique: parseInt(data.mutuelleOptique),
          dentaire: parseInt(data.mutuelleDentaire),
          soins_ville: parseInt(data.soinsVille),
          medecines_alt: ouiToBool(data.medecineAlternative),
          selected: planMutuelle,
        },
      };
    }
    return obj;
  }

  async function SendData() {
    const body = check();
    setLoader(true);
    try {
      const res = await fetch(
        `https://vamj7s8f82.execute-api.eu-west-3.amazonaws.com/prod/sf-request/7?recordId=${recordId}`,
        {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const a = await res.text();
      if (!res.ok) {
        setError(true);
        setErrors(JSON.parse(a).message);
        setLoader(false);

        setTimeout(() => {
          setError(false);
        }, 7000);

        return false;
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  }

  function conditionalDisplay() {
    if (value === 1) {
      return (
        <div className={"card-format"}>
          <div className={"TwoCards"}>
            <Card className="ProductCards" id={"PrevPro"}>
              <input
                type="checkbox"
                className="onoffswitch-checkbox"
                id="prevoyance"
                checked={planPrevoyancePro}
                onChange={(e) => {
                  dispatch(setPrevoyancePro(e.target.checked));
                }}
              />
              <h2 className={"epargne"}>Prévoyance profesionnelle</h2>
              <p>Revenus</p>
              <div id={"champs"}>
                <div className={"under-element-of-card"}>
                  {putSpaces(totalRevenus, 3)} €
                </div>
              </div>
              <p>Charges</p>
              <div id={"champs"}>
                <p></p>
                <div className={"under-element-of-card"}>
                  {putSpaces(totalCharges, 3)} €
                </div>
                <p></p>
              </div>

              <p>Franchise</p>
              <div id={"champs"}>
                <p></p>
                <DropDown>
                  <select
                    name="Franchise"
                    id="lang"
                    value={franchisePrev}
                    onChange={(e) => {
                      dispatch(setFranchisePrevoyancePro(e.target.value));
                    }}
                  >
                    <option value="15/0/3">15/0/3</option>
                    <option value="15/3/3">15/3/3</option>
                    <option value="15/15/15">15/15/15</option>
                    <option value="30/0/3">30/0/3</option>
                    <option value="30/3/3">30/3/3</option>
                    <option value="30/30/30">3 0/30/30</option>
                  </select>
                </DropDown>
                <p></p>
              </div>
              <p>Taux d'invalidité</p>
              <div id={"champs"}>
                <p></p>
                <DropDown>
                  <select
                    name="Taux Invalidite"
                    id="lang"
                    value={tauxInvaliditePro}
                    onChange={(e) => {
                      dispatch(setTauxInvaliditePro(e.target.value));
                    }}
                  >
                    <option value="15%">15%</option>
                    <option value="33%">33%</option>
                  </select>
                </DropDown>
                <p></p>
              </div>
            </Card>
            <Card id={"Pinel"}>
              <input
                type="checkbox"
                className="onoffswitch-checkbox"
                id="pinel"
                checked={planImmobilierPinel}
                onChange={(e) => {
                  dispatch(setImmobilierPinel(e.target.checked));
                }}
              />
              <h2 className={"epargne"}>Immobilier Pinel</h2>
              <p>Mensualité</p>
              <div id={"champs"}>
                <p></p>
                <div className={"under-element-of-card"}>
                  {putSpaces(parseInt(pinelMensualite), 3)} €
                </div>
                <p></p>
              </div>
              <p>Valeur du bien</p>
              <div id={"champs"}>
                <div className={"under-element-of-card"}>
                  {putSpaces(parseInt(pinelPrixDuBien), 3)} €
                </div>
              </div>
              <p>Economie d'impôts</p>
              <div id={"champs"}>
                <p></p>
                <div className={"under-element-of-card"}>
                  {putSpaces(parseInt(reductionImpotsPinel), 3)} €
                </div>
                <p></p>
              </div>
            </Card>
            <Card className="ProductCards" id={"Mut"}>
              <input
                type="checkbox"
                className="onoffswitch-checkbox"
                id="mutuelle"
                checked={planMutuelle}
                onChange={(e) => {
                  dispatch(setMutuelle(e.target.checked));
                }}
              />
              <h2 className={"epargne"}>Mutuelle</h2>
              <p>Optique</p>
              <DropDown>
                <select
                  name="Optique Numero"
                  value={optiqueMutuelle}
                  id="lang"
                  onChange={(e) => {
                    dispatch(setMutuelleOptique(e.target.value));
                  }}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </DropDown>
              <p>Dentaire</p>
              <div id={"champs"}>
                <p></p>
                <DropDown>
                  <select
                    name="Dentaire Numero"
                    value={dentaireMutuelle}
                    id="lang"
                    onChange={(e) => {
                      dispatch(setMutuelleDentaire(e.target.value));
                    }}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </DropDown>
                <p></p>
              </div>
              <p> Soins de ville</p>
              <DropDown>
                <select
                  name="Soins Ville"
                  value={soinsVilleMutuelle}
                  id="lang"
                  onChange={(e) => {
                    dispatch(setSoinsVille(e.target.value));
                  }}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </DropDown>
              <p> Médecines alternatives</p>
              <DropDown>
                <select
                  name="Medecines alternatives"
                  value={medecinesAlternativesMutuelle}
                  id="lang"
                  onChange={(e) => {
                    dispatch(setMedecineAlternative(e.target.value));
                  }}
                >
                  <option value="Oui">Oui</option>
                  <option value="Non">Non</option>
                </select>
              </DropDown>
            </Card>
          </div>
        </div>
      );
    } else {
      return (
        <div className={"card-format"}>
          <div className={"TwoCards"}>
            <Card id={"plan-epargne-retraite"}>
              <input
                type="checkbox"
                className="onoffswitch-checkbox"
                id="planEpargne"
                checked={planEpargneIsChecked}
                onChange={(e) => {
                  dispatch(setPlanEpargneRetraite(e.target.checked));
                }}
              />
              <h2 className={"epargne"}>Plan Epargne Retraite</h2>
              <div className={"CardPlacement"}>
                <p>Mensualité</p>

                <div id={"champs"}>
                  <MyInput
                    value={versementPer}
                    placeholder={
                      "Plafond Def. : " +
                      putSpaces(plafondDefiscalisation, 3)
                    }
                    onChange={(e) => {
                      dispatch(
                        setMensualiteEpargneRetraite(
                          putSpaces(e.target.value, 3)
                        )
                      );
                    }}
                  ></MyInput>
                </div>
                <p>Age de départ en retraite</p>
                <div id={"champs"}>
                  <MyInput
                    value={ageDepartEnRetraite}
                    onChange={(e) => {
                      dispatch(setAgeDepartRetraite(e.target.value));
                    }}
                  ></MyInput>
                </div>
                <p>Capital au terme</p>
                <div id={"champs"}>
                  <p></p>
                  <div className={"under-element-of-card"}>
                    {putSpaces(capitalTerme, 3)} €
                  </div>
                  <p></p>
                </div>
                <p>Economie d'impôts</p>
                <div id={"champs"}>
                  <p></p>
                  <div className={"under-element-of-card"}>
                    {putSpaces(myEconomieImpotsTerme, 3)} €
                  </div>
                  <p></p>
                </div>
              </div>
            </Card>
            <Card id={"assurance-vie"}>
              <input
                type="checkbox"
                className="onoffswitch-checkbox"
                id="assuranceVie"
                checked={planAssuranceVie}
                onChange={(e) => {
                  dispatch(setAssuranceVie(e.target.checked));
                }}
              />
              <h2 className={"epargne"}>Assurance Vie</h2>
              <div className={"CardPlacement"}>
                <p>Mensualité</p>
                <div id={"champs"}>
                  <MyInput
                    placeholder={"prix"}
                    value={mensualiteAssuranceVie}
                    onChange={(e) => {
                      dispatch(setMensualiteAssuranceVie(e.target.value));
                    }}
                  ></MyInput>
                </div>
                <p>Versement libre</p>
                <div id={"champs"}>
                  <MyInput
                    placeholder={"prix"}
                    value={versementLibreAssuranceVie}
                    onChange={(e) => {
                      dispatch(setVersementLibreAssuranceVie(e.target.value));
                    }}
                  ></MyInput>
                </div>
                <p>Profil d'investissement</p>
                <div id={"champs"}>
                  <DropDown>
                    <select
                      name="Profil Investissement"
                      value={profilInvestisseurAssuranceVie}
                      id="lang"
                      onChange={(e) => {
                        dispatch(setProfilInvestissement(e.target.value));
                      }}
                    >
                      <option value="Equilibre">Equilibré</option>
                      <option value="Prudent">Prudent</option>
                      <option value="Dynamique">Dynamique</option>
                    </select>
                  </DropDown>
                  <p></p>
                </div>

                <p>Produit structuré</p>
                <div id={"champs"}>
                  <p></p>
                  <DropDown>
                    <select
                      name="Statut Familial"
                      id="lang"
                      value={produitStructure}
                      onChange={(e) => {
                        dispatch(setProduitStructure(e.target.value));
                      }}
                    >
                      <option value="oui">Oui</option>
                      <option value="non">Non</option>
                    </select>
                  </DropDown>
                  <p></p>
                </div>
              </div>
            </Card>
            <Card id={"assurance-emprunteur"}>
              <input
                type="checkbox"
                className="onoffswitch-checkbox"
                id="assuranceEmprunteur"
                checked={planAssuranceEmprunteur}
                onChange={(e) => {
                  dispatch(setAssuranceEmprunteur(e.target.checked));
                }}
              />
              <h2 className={"epargne"}>Assurance emprunteur </h2>
              <div className={"CardPlacement"}>
                <p>Mensualité</p>
                <div id={"champs"}>
                  <MyInput
                    value={mensualiteAssuranceEmprunteur}
                    placeholder={"prix"}
                    onChange={(e) => {
                      dispatch(
                        setMensualiteAssuranceEmprunteur(e.target.value)
                      );
                    }}
                  ></MyInput>
                </div>
                <p>Capital restant dû</p>

                <div id={"champs"}>
                  <p></p>
                  <MyInput
                    placeholder={"prix"}
                    value={capitalRestantDuAssuranceEmprunteur}
                    onChange={(e) => {
                      dispatch(
                        setCapitalRestantAssuranceEmprunteur(e.target.value)
                      );
                    }}
                  ></MyInput>
                  <p></p>
                </div>
                <p>Durée</p>
                <div id={"champs"}>
                  <p></p>
                  <MyInput
                    value={dureeAssuranceEmprunteur}
                    placeholder={"Durée"}
                    onChange={(e) => {
                      dispatch(setDureeAssuranceEmprunteur(e.target.value));
                    }}
                  ></MyInput>
                  <p></p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      );
    }
  }

  const dataIsLoaded = useSelector((state) => state.backend.isDataLoaded);
  const navigate = useNavigate();

  useEffect(() => {
    if (!dataIsLoaded || recordId === undefined || recordId === "" || searchParams.get("securityToken") === undefined || searchParams.get("securityToken") === "") {
      const interval = setInterval(() => {
        navigate(`/?recordId=${recordId}&securityToken=${searchParams.get("securityToken")}`, { replace: true })
      }, 200);

      return function () {
        clearInterval(interval);
      }
        
    }
  }, [dataIsLoaded, navigate, recordId, searchParams]);

  return (
    <div>
      <Loader isActive={loader} />
      <Alert isActive={error} errors={errors} />
      <div className={"page-format"}>
        <h1>Les produits disponibles chez Lexem 🤝</h1>
        <div className={"ListProduit"}>
          <Tabs
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            value={value}
            onChange={handleChange}
            className={"Onglet"}
          >
            <Tab value={0} label="Produits Un" />
            <Tab value={1} label="Produits Deux" />
          </Tabs>
          <div className={"under-element-of-card"}>
            Reste à vivre disponible
            <p>{putSpaces(resteAVivre, 3)} €</p>
          </div>
        </div>
        {conditionalDisplay()}
      </div>
      <Navigation
        goto={`/ProfileInvestisseur?recordId=${recordId}&securityToken=${searchParams.get(
          "securityToken"
        )}`}
        goback={`/ObjectifsPatrimoniaux?recordId=${recordId}&securityToken=${searchParams.get(
          "securityToken"
        )}`}
        check={SendData}
      />
    </div>
  );
}
