import React, { useEffect, useState } from "react";
import { Card } from "../../component/card/card";
import "./epargne.scss";
import { Navigation } from "../../component/navigation/navigation";
// import {useDispatch} from "react-redux";
import { Protection } from "./protection";
import { DynamicArrayEpargne } from "./dynamicArrayEpargne";
import { useSelector } from "react-redux";
import { addEpargne, deleteEpargne } from "../../store/storeData";
import { regexRemoveSpaces } from "../../utils/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../component/loader/Loader";
import Alert from "../../component/alert/alert";

export function Epargne() {
  const fields = useSelector((state) => state.inputEpargne.epargne);
  const data = useSelector((store) => store.inputEpargne);
  const data_protec = useSelector((store) => store.inputProtection);
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get("recordId");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);

  function GetBiggestCapital(product) {
    let result = 0;

    for (let i = 0; i < data.epargne.length; ++i) {
      if (data.epargne[i].typeEpargne === product)
        result += parseInt(regexRemoveSpaces(data.epargne[i].capitalConstitue));
    }
    return result;
  }

  function GetBiggestMensualite(product, type) {
    let result = 0;

    if (type === "epargne") {
      for (let i = 0; i < data.epargne.length; ++i) {
        if (data.epargne[i].typeEpargne === product)
          result += parseInt(regexRemoveSpaces(data.epargne[i].mensualite));
      }
    }
    if (type === "protection") {
      for (let i = 0; i < data_protec.protection.length; ++i) {
        if (data_protec.protection[i].typeProtection === product)
          result += parseInt(
            regexRemoveSpaces(data_protec.protection[i].mensualite)
          );
      }
    }
    return result;
  }

  function GetBigestInformation(product, field, type) {
    if (type === "epargne") {
      for (let i = 0; i < data.epargne.length; ++i) {
        if (data.epargne[i].typeEpargne === product && field === "banque")
          return data.epargne[i].assureur;
        if (data.epargne[i].typeEpargne === product && field === "butRecherche")
          return data.epargne[i].butRecherche;
      }
    }
    if (type === "protection") {
      for (let i = 0; i < data_protec.protection.length; ++i) {
        if (
          data_protec.protection[i].typeProtection === product &&
          field === "banque"
        )
          return data_protec.protection[i].assureur;
      }
    }
  }

  function ThereIsProduct(product, type) {
    if (type === "epargne") {
      for (let i = 0; i < data.epargne.length; ++i) {
        if (data.epargne[i].typeEpargne === product) return true;
      }
    }
    if (type === "protection") {
      for (let i = 0; i < data_protec.protection.length; ++i) {
        if (data_protec.protection[i].typeProtection === product) return true;
      }
    }
    return false;
  }

  function GetEpargneProducts() {
    const obj = [];
    let tmp = {};

    if (ThereIsProduct("AV", "epargne") === true) {
      tmp = {};
      tmp["produit_depargne"] = "AV";
      tmp["capital_constitue"] = GetBiggestCapital("AV");
      tmp["mensualites"] = GetBiggestMensualite("AV", "epargne");
      tmp["banque"] = GetBigestInformation(
        "AV",
        "banque",
        "epargne"
      );
      tmp["but_recherche"] = GetBigestInformation(
        "AV",
        "butRecherche",
        "epargne"
      );
      obj.push(tmp);
    }
    if (ThereIsProduct("CEL", "epargne") === true) {
      tmp = {};
      tmp["produit_depargne"] = "CEL";
      tmp["capital_constitue"] = GetBiggestCapital("CEL");
      tmp["mensualites"] = GetBiggestMensualite("CEL", "epargne");
      tmp["banque"] = GetBigestInformation("CEL", "banque", "epargne");
      tmp["but_recherche"] = GetBigestInformation(
        "CEL",
        "butRecherche",
        "epargne"
      );
      obj.push(tmp);
    }
    if (ThereIsProduct("CompteTitrePEA", "epargne") === true) {
      tmp = {};
      tmp["produit_depargne"] = "Compte_Titre_PEA";
      tmp["capital_constitue"] = GetBiggestCapital("CompteTitrePEA");
      tmp["mensualites"] = GetBiggestMensualite("CompteTitrePEA", "epargne");
      tmp["banque"] = GetBigestInformation(
        "CompteTitrePEA",
        "banque",
        "epargne"
      );
      tmp["but_recherche"] = GetBigestInformation(
        "CompteTitrePEA",
        "butRecherche",
        "epargne"
      );
      obj.push(tmp);
    }

    if (ThereIsProduct("LA", "epargne") === true) {
      tmp = {};
      tmp["produit_depargne"] = "LA";
      tmp["capital_constitue"] = GetBiggestCapital("LA");
      tmp["mensualites"] = GetBiggestMensualite("LA", "epargne");
      tmp["banque"] = GetBigestInformation("LA", "banque", "epargne");
      tmp["but_recherche"] = GetBigestInformation(
        "LA",
        "butRecherche",
        "epargne"
      );
      obj.push(tmp);
    }

    if (ThereIsProduct("LDD", "epargne") === true) {
      tmp = {};
      tmp["produit_depargne"] = "LDD";
      tmp["capital_constitue"] = GetBiggestCapital("LDD");
      tmp["mensualites"] = GetBiggestMensualite("LDD", "epargne");
      tmp["banque"] = GetBigestInformation("LDD", "banque", "epargne");
      tmp["but_recherche"] = GetBigestInformation(
        "LDD",
        "butRecherche",
        "epargne"
      );
      obj.push(tmp);
    }

    if (ThereIsProduct("LEP", "epargne") === true) {
      tmp = {};
      tmp["produit_depargne"] = "LE";
      tmp["capital_constitue"] = GetBiggestCapital("LEP");
      tmp["mensualites"] = GetBiggestMensualite("LEP", "epargne");
      tmp["banque"] = GetBigestInformation("LEP", "banque", "epargne");
      tmp["but_recherche"] = GetBigestInformation(
        "LEP",
        "butRecherche",
        "epargne"
      );
      obj.push(tmp);
    }

    if (ThereIsProduct("LJ", "epargne") === true) {
      tmp = {};
      tmp["produit_depargne"] = "LJ";
      tmp["capital_constitue"] = GetBiggestCapital("LJ");
      tmp["mensualites"] = GetBiggestMensualite("LJ", "epargne");
      tmp["banque"] = GetBigestInformation("LJ", "banque", "epargne");
      tmp["but_recherche"] = GetBigestInformation(
        "LJ",
        "butRecherche",
        "epargne"
      );
      obj.push(tmp);
    }

    if (ThereIsProduct("PER", "epargne") === true) {
      tmp = {};
      tmp["produit_depargne"] = "PER";
      tmp["capital_constitue"] = GetBiggestCapital("PER");
      tmp["mensualites"] = GetBiggestMensualite("PER", "epargne");
      tmp["banque"] = GetBigestInformation("PER", "banque", "epargne");
      tmp["but_recherche"] = GetBigestInformation(
        "PER",
        "butRecherche",
        "epargne"
      );
      obj.push(tmp);
    }

    if (ThereIsProduct("Madelin", "epargne") === true) {
      tmp = {};
      tmp["produit_depargne"] = "Madelin";
      tmp["capital_constitue"] = GetBiggestCapital("Madelin");
      tmp["mensualites"] = GetBiggestMensualite("Madelin", "epargne");
      tmp["banque"] = GetBigestInformation("Madelin", "banque", "epargne");
      tmp["but_recherche"] = GetBigestInformation(
        "Madelin",
        "butRecherche",
        "epargne"
      );
      obj.push(tmp);
    }

    if (ThereIsProduct("PERP", "epargne") === true) {
      tmp = {};
      tmp["produit_depargne"] = "PERP";
      tmp["capital_constitue"] = GetBiggestCapital("PERP");
      tmp["mensualites"] = GetBiggestMensualite("PERP", "epargne");
      tmp["banque"] = GetBigestInformation("PERP", "banque", "epargne");
      tmp["but_recherche"] = GetBigestInformation(
        "PERP",
        "butRecherche",
        "epargne"
      );
      obj.push(tmp);
    }

    if (ThereIsProduct("PEL", "epargne") === true) {
      tmp = {};
      tmp["produit_depargne"] = "PEL";
      tmp["capital_constitue"] = GetBiggestCapital("PEL");
      tmp["mensualites"] = GetBiggestMensualite("PEL", "epargne");
      tmp["banque"] = GetBigestInformation("PEL", "banque", "epargne");
      tmp["but_recherche"] = GetBigestInformation(
        "PEL",
        "butRecherche",
        "epargne"
      );
      obj.push(tmp);
    }
    return obj;
  }

  function GetProtectionProducts() {
    const obj = [];
    let tmp = {};

    if (ThereIsProduct("PIJ", "protection") === true) {
      tmp = {};
      tmp["produit_protec"] = "PIJ";
      tmp["mensualites"] = GetBiggestMensualite("PIJ", "protection");
      tmp["banque"] = GetBigestInformation("PIJ", "banque", "protection");
      obj.push(tmp);
    }
    if (ThereIsProduct("MS", "protection") === true) {
      tmp = {};
      tmp["produit_protec"] = "MS";
      tmp["mensualites"] = GetBiggestMensualite("MS", "protection");
      tmp["banque"] = GetBigestInformation("MS", "banque", "protection");
      obj.push(tmp);
    }
    if (ThereIsProduct("HC", "protection") === true) {
      tmp = {};
      tmp["produit_protec"] = "HC";
      tmp["mensualites"] = GetBiggestMensualite("HC", "protection");
      tmp["banque"] = GetBigestInformation("HC", "banque", "protection");
      obj.push(tmp);
    }
    if (ThereIsProduct("C_C", "protection") === true) {
      tmp = {};
      tmp["produit_protec"] = "C_C";
      tmp["mensualites"] = GetBiggestMensualite("C_C", "protection");
      tmp["banque"] = GetBigestInformation("C_C", "banque", "protection");
      obj.push(tmp);
    }
    return obj;
  }

  function ConvertData() {
    const obj = {
      epargne: GetEpargneProducts(),
      protection: GetProtectionProducts(),
    };
    return obj;
  }

  async function SendData() {
    setLoader(true);
    try {
      const res = await fetch(
        `https://vamj7s8f82.execute-api.eu-west-3.amazonaws.com/prod/sf-request/3?recordId=${recordId}`,
        {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(ConvertData()),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!res.ok) {
        const a = await res.text();
        setError(true);
        setErrors(JSON.parse(a).message);
        setLoader(false);

        setTimeout(() => {
          setError(false);
        }, 7000);

        return false;
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  }

  const dataIsLoaded = useSelector((state) => state.backend.isDataLoaded);
  const navigate = useNavigate();

  useEffect(() => {
    if (!dataIsLoaded || recordId === undefined || recordId === "" || searchParams.get("securityToken") === undefined || searchParams.get("securityToken") === "") {
      const interval = setInterval(() => {
        navigate(`/?recordId=${recordId}&securityToken=${searchParams.get("securityToken")}`, { replace: true })
      }, 200);

      return function () {
        clearInterval(interval);
      }
        
    }
  }, [dataIsLoaded, navigate, recordId, searchParams]);

  return (
    <div>
      <Loader isActive={loader} />
      <Alert isActive={error} errors={errors} />
      <div className={"page-format"}>
        <div>
          <h1>Analysons votre épargne et vos produits de protection 🤥</h1>
        </div>
        <div className={"format-epargne"}>
          <Card>
            <h3>Produit d'Epargne</h3>
            <DynamicArrayEpargne
              create={"Ajouter un produit d'épargne"}
              delete={"Effacer toute les produits d'épargnes"}
              fields={fields}
              addValueReducer={addEpargne}
              removeValueReducer={deleteEpargne}
            ></DynamicArrayEpargne>
          </Card>
          <Protection />
        </div>
      </div>
      <Navigation
        goto={`/RevenusFoyer?recordId=${recordId}&securityToken=${searchParams.get(
          "securityToken"
        )}`}
        goback={`/ActifImmobiliers?recordId=${recordId}&securityToken=${searchParams.get(
          "securityToken"
        )}`}
        check={SendData}
      />
    </div>
  );
}
