import React, { useEffect, useState } from "react";
import { Card } from "../../component/card/card";
import { Navigation } from "../../component/navigation/navigation";
import { addCharge, deleteCharges } from "../../store/storeData";
import { DynamicArrayCharges } from "./dynamicArrayCharges";
import { useSelector } from "react-redux";
import { regexRemoveSpaces } from "../../utils/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../component/loader/Loader";
import Alert from "../../component/alert/alert";

export function ChargesFoyer() {
  const fields = useSelector((state) => state.inputChargesFoyer.charges);
  const data = useSelector((store) => store.inputChargesFoyer);
  const [searchParams] = useSearchParams();
  const recordId = searchParams.get("recordId");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);

  function getChargesTotal(charge, who) {
    let result = 0;

    for (let i = 0; i < data.charges.length; ++i) {
      if (data.charges[i].typeCharges === charge && who === "vous") {
        result += parseInt(regexRemoveSpaces(data.charges[i].mensualite));
      } else if (data.charges[i].typeCharges === charge && who === "conjoint")
        result += parseInt(
          regexRemoveSpaces(data.charges[i].mensualiteConjoint)
        );
    }
    return result;
  }

  function getYourCharges(who) {
    const obj = [
      { type: "loyer", mensualite: getChargesTotal("loyer", who) },
      {
        type: "credit_conso",
        mensualite: getChargesTotal("credit_conso", who),
      },
      {
        type: "credit_auto",
        mensualite: getChargesTotal("credit_auto", who),
      },
      {
        type: "credit_immo",
        mensualite: getChargesTotal("credit_immo", who),
      },
      {
        type: "impot_revenu",
        mensualite: getChargesTotal("impot_revenu", who),
      },
      {
        type: "taxe_fonciere",
        mensualite: getChargesTotal("taxe_fonciere", who),
      },
      {
        type: "pension_alimentaire",
        mensualite: getChargesTotal("pension_alimentaire", who),
      },
      {
        type: "autres",
        mensualite: getChargesTotal("autres", who),
      },
    ];
    return obj;
  }

  function ConvertData() {
    const obj = {
      vous: getYourCharges("vous"),
      conjoint: getYourCharges("conjoint"),
    };
    return obj;
  }

  async function SendData() {
    setLoader(true);
    try {
      const res = await fetch(
        `https://vamj7s8f82.execute-api.eu-west-3.amazonaws.com/prod/sf-request/5?recordId=${recordId}`,
        {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(ConvertData()),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!res.ok) {
        const a = await res.text();
        setError(true);
        setLoader(false);
        setErrors(JSON.parse(a.message));

        setTimeout(() => {
          setError(false);
        }, 7000);

        return false;
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  }

  const dataIsLoaded = useSelector((state) => state.backend.isDataLoaded);
  const navigate = useNavigate();

  useEffect(() => {
    if (!dataIsLoaded || recordId === undefined || recordId === "" || searchParams.get("securityToken") === undefined || searchParams.get("securityToken") === "") {
      const interval = setInterval(() => {
        navigate(`/?recordId=${recordId}&securityToken=${searchParams.get("securityToken")}`, { replace: true })
      }, 200);

      return function () {
        clearInterval(interval);
      }
        
    }
  }, [dataIsLoaded, navigate, recordId, searchParams]);

  return (
    <div>
      <Alert isActive={error} errors={errors} />
      <Loader isActive={loader} />
      <div className={"page-format"}>
        <div>
          <h1>Etudions les charges de votre foyer 🫶</h1>
        </div>
        <div>
          <Card>
            <DynamicArrayCharges
              create={"Ajouter une charge"}
              delete={"Effacer toute les charges"}
              fields={fields}
              addValueReducer={addCharge}
              removeValueReducer={deleteCharges}
            ></DynamicArrayCharges>
          </Card>
        </div>
      </div>
      <Navigation
        goto={`/ResumeSituation?recordId=${recordId}&securityToken=${searchParams.get(
          "securityToken"
        )}`}
        goback={`/RevenusFoyer?recordId=${recordId}&securityToken=${searchParams.get(
          "securityToken"
        )}`}
        check={SendData}
      />
    </div>
  );
}
