import React from "react";
import "./button.scss"
export function Button(props) {
    return (
        <div>
            <button type={"button"} className={props.className} onClick={props.onClick}>
                {props.children}
            </button>
        </div>
    );
}