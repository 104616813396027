import React, {useState} from "react";
import {Card} from "../../component/card/card";
import "./intro.scss"
import {MyInput} from "../../component/input/myInput";
import { setPhoneUser} from "../../store/storeData";
import {useDispatch} from "react-redux";

export function Intro() {

    const dispatch = useDispatch();
    const [, setVerif] = useState(undefined);
    return (
        <div className={"phone-format"}>
            <div className={"title-phone"}>
            <h1>Bienvenue chez Lexem, le conseil financier 👋</h1>
            </div>
            <Card>
                <div className={"CardPlacement"}>
                    <p>
                        Numéro de téléphone
                    </p>
                    <div id={"champs"}>
                        <MyInput placeholder={"Numéro de téléphone"} onChange={(e) => {
                            dispatch(setPhoneUser(e.target.value));
                        }
                        }></MyInput>
                    </div>
                    <p>
                        Numéro de vérification
                    </p>
                    <div id={"champs"}>
                        <MyInput placeholder={"Numéro de verification"} onChange={(e) => {
                            setVerif(e.target.value);
                        }
                        }></MyInput>
                    </div>
                    <div className={"button-verif"}>
                        <button type={"button"} className={"Etape-right"}>Envoyer Code
                        </button>
                        <button type={"button"} className={"Etape-left"}>Vérification
                        </button>
                    </div>
                </div>
            </Card>
        </div>
    );
}